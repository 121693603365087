
export default {
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    viewGuide () {
      return this.$store.state.system.viewGuide
    },
    family () {
      return this.$store.getters["families/active"]
    }
  },
  watch: {
    viewGuide () {
      this.checkGuide()
    }
  },
  methods: {
    setGuide (e) {
      this.$store.commit("system/SET_GUIDE", e)
    },
    checkGuide () {
      if (this.viewGuide !== false) {
        this.dialog = true
      } else {
        this.dialog = false
      }
    }
  }
}
