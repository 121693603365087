// State ======================
import lmsActions from "../api/LmsActions.js";
import lmsMutations from "../api/LmsMutations.js";
import lmsGetters from "../api/LmsGetters.js";
export const namespaced = true;

export const state = () => ({
  type: "lmsTags",
  relations: [],
  all: {},
  reference: {},
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 20,
    sort: true,
    sortBy: "updated_at",
    page: 1,
    descending: true,
    sortDesc: [],
  },
  total: 0,
  print: false,
  print_path: "",
  export: false,
  search: null,
  bound: {},
  stub: {},
  filter: {},
  include: [],
  headers: {},
  notifications: {},
});

// Mutations ==================

export const mutations = {
  ...lmsMutations,
};

// Actions ====================

export const actions = {
  ...lmsActions,
};

// Getters ====================

export const getters = {
  ...lmsGetters,
};
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
