
export default {
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      drawer: false
    }
  },
  computed: {
    lesson () {
      return this.$store.getters["lessons/active"]
    },
    course () {
      return this.$store.getters["courses/active"]
    },
    section () {
      return this.$store.getters["sections/find"](this.lesson.sections.id)
    },
    resources () {
      if (this.preview) {
        return []
      }
      return this.lesson.files.filter(
        file => file.feature === "resources"
      )
    }
  },
  watch: {
    open: {
      handler (newValue, oldValue) {
        if (newValue) {
          this.drawer = true
        } else {
          this.drawer = false
        }
      },
      immediate: true
    }
  },
  methods: {
    close () {
      this.drawer = false
      this.$emit("close")
    }
  }

}
