
// import Search from "~/components/Search.vue";

export default {
  data () {
    return {
      clipped: true,
      drawer: true,
      fixed: true,
      items: [
        // {
        //   icon: "mdi-home",
        //   title: "Dashboard",
        //   to: "/lms",
        // },
        {
          icon: "mdi-book",
          title: "Courses",
          to: "/lms/lmsCourses"
        },
        // {
        //   icon: "mdi-wall",
        //   title: "Block Builder",
        //   to: "/lms/lmsDirectives"
        // },
        {
          icon: "mdi-book-open-variant",
          title: "Twisi Links",
          to: "/lms/lmsExpiringlinks"
        },
        {
          icon: "mdi-ticket-account",
          title: "Users",
          to: "/lms/lmsUsers"
        }

      ],
      miniVariant: true,
      right: true,
      rightDrawer: false,
      title: "EDGELMS"
    }
  },
  // async fetch ({ store }) {
  //   await store.dispatch("lmsSectionschemas/list")
  //   await store.dispatch("users/list")
  //   await store.dispatch("lmsCourses/list")
  //   await store.dispatch("lmsDirectives/list")
  // },
  computed: {
    path () {
      return this.$store.state.lmsSystem.activeView
    },
    subdomain () {
      return "HOMESTEAD" // this.$auth.user.data.local.subdomain;
    },
    test () {
      return this.$vuetify.theme.themes.light
    }
  },
  methods: {
    logout () {
      this.$auth.logout().then(() => this.$router.push("/login"))
    }
  },
  mounted () {
    this.$vuetify.theme.themes.light = {
      primary: "#32485f",
      secondary: "#51b988",
      accent: "#E1E2E1",
      info: "#3699dd",
      warning: "#a160be",
      error: "#d65c4f",
      success: "#51b988",
      background: "#f2f5f8"
    }
    this.$vuetify.theme = {
      themes: { light: true }
    }
  }

  // components: {
  //   Search
  // }
}
