
export default {
  data () {
    return {
      fixed: false
    }
  },
  computed: {
    viewGuide () {
      return this.$store.state.system.viewGuide
    }
  }
}
