// State ======================
import Vue from "vue";
import moment from "moment";
import globalActions from "../api/GlobalActions.js";
import globalMutations from "../api/GlobalMutations.js";
import globalGetters from "../api/GlobalGetters.js";

export const state = () => ( {
    type: "courses",
    relations: [
        { name: "lessons", value: "lessons" },
        { name: "sections", value: "sections" },
    ],
    all: {},
    reference: {
        /* for showing paginated resources ... needs a bit more work. */
    },
    active: false,
    page: 1,
    pageCount: 0,
    pagination: {
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
        itemsPerPage: 150,
        sort: true,
        sortBy: "name",
        page: 1,
        sortDesc: [],
    },
    total: 0,
    print: false,
    print_path: "",
    export: false,
    search: null,
    //this will need to by dynamic based on user config valuese
    bound: {},
    stub: {
        sections: [],
        lessons: [],
    },
    filter: {},
    //members are called contractors on the server
    include: [ "cover", "files" ],
    headers: {},
    notifications: {},
} );

// Mutations ==================

export const mutations = {
    ...globalMutations,
    SET_CURRENT_LESSON(state, { courseId, lessonId }) {
        Object.values(state.all).find(course => course.id == courseId)["last_lesson_id"] = lessonId;
    },
    ENROLL_USER_IN_THE_COURSE(state, payload) {
        Object.values(state.all).find(course => course.id == payload.courseId)["enrolled"] = true;
    }
};

// Actions ====================

export const actions = {
    ...globalActions,
};

// Getters ====================

export const getters = {
    ...globalGetters,
};
