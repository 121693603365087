// State ======================
import Vue from "vue";
import moment from "moment";
import globalActions from "../api/GlobalActions.js";
import globalMutations from "../api/GlobalMutations.js";
import globalGetters from "../api/GlobalGetters.js";

export const state = () => ({
  type: "persons",
  relations: [],
  all: {},
  reference: {
    /* for showing paginated resources ... needs a bit more work. */
  },
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: "name",
    page: 1,
    sortDesc: [],
  },
  total: 0,
  print: false,
  print_path: "",
  export: false,
  search: null,
  //this will need to by dynamic based on user config valuese
  bound: {},
  stub: {},
  filter: {},
  //members are called contractors on the server
  include: "",
  headers: {},
  notifications: {},
});

// Mutations ==================

export const mutations = {
  ...globalMutations,
};

// Actions ====================

export const actions = {
  ...globalActions,
};

// Getters ====================

export const getters = {
  ...globalGetters,
};
