import { schema, normalize } from "normalizr";

export default (ctx, inject) => {
  const lmsrelationships = {
    lmsCertificates: new schema.Entity("lmsCertificates"),
    lmsBlocks: new schema.Entity("lmsBlocks"),
    lmsCourses: new schema.Entity("lmsCourses"),
    lmsDirectives: new schema.Entity("lmsDirectives"),
    expiringlinks: new schema.Entity("expiringlinks"),
    lmsExpiringlinks: new schema.Entity("lmsExpiringlinks"),
    lmsLessons: new schema.Entity("lmsLessons"),
    families: new schema.Entity("families"),
    lmsFamilies: new schema.Entity("lmsFamilies"),
    lmsFiles: new schema.Entity("lmsFiles"),
    lmsFilterschemas: new schema.Entity("lmsFilterschemas"),
    lmsSectionschemas: new schema.Entity("lmsSectionschemas"),
    lmsSections: new schema.Entity("lmsSections"),
    lmsQuizzes: new schema.Entity("lmsQuizzes"),
    lmsTags: new schema.Entity("lmsTags"),
    users: new schema.Entity("users"),
  };
  lmsrelationships.lmsBlocks.define({
    lmsSessons: [lmsrelationships["lmsLessons"]],
    lmsBlocks: [lmsrelationships["lmsBlocks"]],
    lmsFiles: [lmsrelationships["lmsFiles"]],
  });
  lmsrelationships.lmsCertificates.define({
    lmsCourse: lmsrelationships["lmsCourses"],
    user: lmsrelationships["users"],
    lmsFile: lmsrelationships["lmsFiles"],
  });
  lmsrelationships.lmsDirectives.define({
    subDirectives: [lmsrelationships["lmsDirectives"]],
  });
  lmsrelationships.expiringlinks.define({});
  lmsrelationships.families.define({});
  lmsrelationships.lmsExpiringlinks.define({});
  lmsrelationships.lmsFamilies.define({});
  lmsrelationships.lmsFiles.define({
    lmsTags: [lmsrelationships["lmsTags"]],
  });
  lmsrelationships.lmsCourses.define({
    lmsSections: [lmsrelationships["lmsSections"]],
    lmsLessons: [lmsrelationships["lmsLessons"]],
    lmsFiles: [lmsrelationships["lmsFiles"]],
    users: [lmsrelationships["users"]],
  });
  // lmsrelationships.documents.define({});
  lmsrelationships.lmsFilterschemas.define({});

  lmsrelationships.lmsLessons.define({
    lmsBlocks: [lmsrelationships["lmsBlocks"]],
    lmsSections: lmsrelationships["lmsSections"],
    lmsCourses: lmsrelationships["lmsCourses"],
  });
  lmsrelationships.lmsSectionschemas.define({});
  lmsrelationships.lmsSections.define({
    lmsLessons: [lmsrelationships["lmsLessons"]],
    lmsCourses: [lmsrelationships["lmsCourses"]],
  });
  lmsrelationships.lmsQuizzes.define({
    lmsBlocks: [lmsrelationships["lmsBlocks"]],
    lmsLessons: [lmsrelationships["lmsLessons"]],
    lmsCourses: [lmsrelationships["lmsCourses"]],
  });

  lmsrelationships.users.define({
    lmsCertificates: [lmsrelationships["lmsCertificates"]],
    lmsCourses: [lmsrelationships["lmsCourses"]],
  });
  lmsrelationships.lmsTags.define({});

  inject("lmsrelationships", lmsrelationships);
};
