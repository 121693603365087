
export default {
  // props: {
  //     loading: {
  //         type: Boolean,
  //         required: true
  //     }
  // },
  data: () => ({
    loading: false
  }),
  methods: {
    start () {
      this.loading = true
    },
    finish () {
      this.loading = false
    }
  }
}
