import Vue from "vue"
import _ from "lodash"
import "animate.css"
import "intersection-observer" // for cross-browser support
import VueAnime from "vue-animejs"

// import VuetifyDaterangePicker from "vuetify-daterange-picker";
// import "vuetify-daterange-picker/dist/vuetify-daterange-picker.css";

// const ComponentContext = require.context(
//   "../components/common",
//   true,
//   /\.vue$/i,
//   "lazy"
// )

// ComponentContext.keys().forEach((componentFilePath) => {
//   const componentName = componentFilePath
//     .split("/")
//     .pop()
//     .split(".")[0]
//   Vue.component(componentName, () => ComponentContext(componentFilePath))
// })

Vue.mixin({
  methods: {
    objHasAndNotEmpty (obj, field) {
      if (this.objHas(obj, field)) {
        if (obj[field]) {
          return true
        }
      }
      return false
    },
    objHas (obj, field) {
      if (typeof obj === "object") {
        return Object.prototype.hasOwnProperty.call(obj, field)
      }
      return false
    },
    showErrorSnack (errorMessage) {
      this.$store.commit("system/SET_SNACK", "ERROR: " + errorMessage)
      const snackSettings = {
        color: "error",
        top: false,
        timeout: 2000,
        right: true
      }
      this.$store.commit("system/set_snackSettings", snackSettings)
    },
    showSuccessSnack (errorMessage) {
      this.$store.commit("system/SET_SNACK", "Success: " + errorMessage)
      const snackSettings = {
        color: "secondary",
        top: false,
        timeout: 2000,
        right: true
      }
      this.$store.commit("system/set_snackSettings", snackSettings)
    },
    intersects (array, key, values) {
      if (typeof values === "string") {
        values = values.split(",").map(v => v.toString().trim())
      }

      return array.filter(data => _.intersection(values, data[key]).length > 0)
    },
    like (array, key, value) {
      return array.filter(data => JSON.stringify(data[key]).replace(/("\w+":)/g, "").toLowerCase().includes(value.toLowerCase()))
    },
    isEmpty (value) {
      // If value is null on undefined:
      if (value === null) { return true }

      // By types:
      if (typeof value === "boolean") { return !value }
      if (typeof value === "number") { return value === 0 }
      if (typeof value === "string") { return value === "0" || value.length === 0 }

      // Empty arrays:
      if (Array.isArray(value)) { return value.length === 0 }

      // Empty objects:
      if (value.toString === Object.prototype.toString) {
        switch (value.toString()) {
          case "[object File]":
          case "[object Map]":
          case "[object Set]": {
            return value.size === 0
          }
          case "[object Object]": {
            for (const key in value) {
              if (Object.prototype.hasOwnProperty.call(value, key)) { return false }
            }

            return true
          }
        }
      }

      // Everything else...
      return false
    }
  }
})

Vue.use(_)
Vue.use(VueAnime)
