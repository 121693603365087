import { schema } from "normalizr"

export default (ctx, inject) => {
  const relationships = {
    users: new schema.Entity("users"),
    courses: new schema.Entity("courses"),
    lessons: new schema.Entity("lessons"),
    sections: new schema.Entity("sections"),
    files: new schema.Entity("files"),
    certificates: new schema.Entity("certificates"),

    presentations: new schema.Entity("presentations"),
    persons: new schema.Entity("persons"),
    families: new schema.Entity("families")
  }

  relationships.courses.define({
    lessons: [relationships.lessons],
    sections: [relationships.sections],
    files: [relationships.files]
  })

  relationships.certificates.define({
    file: relationships.files,
    user: relationships.users,
    course: relationships.courses
  })

  relationships.files.define({})

  relationships.lessons.define({
    courses: relationships.courses,
    sections: relationships.sections
  })

  relationships.sections.define({
    lessons: [relationships.lessons],
    courses: [relationships.courses]
  })

  relationships.presentations.define({})
  relationships.persons.define({})
  relationships.families.define({
    presentations: [relationships.presentations],
    persons: [relationships.persons],
    main: relationships.persons
  })

  relationships.users.define({
    courses: [relationships.courses]
  })

  inject("relationships", relationships)
}
