
export default {
  data() {
    return {
      show: false,
      message: "",
    };
  },
  computed: {
    settings() {
      return this.$store.state.lmsSystem.snackSettings;
    },
    snack() {
      return this.$store.state.lmsSystem.snack;
    },
  },
  mounted() {
    this.checksnack();
  },
  watch: {
    snack() {
      this.checksnack();
    },
  },
  methods: {
    checksnack() {
      if (this.snack !== "") {
        this.show = true;
        this.message = this.snack;
        this.$store.commit("lmsSystem/SET_SNACK", "");
      }
    },
  },
};
