import Vue from "vue"
import moment from "moment"

Vue.filter("formatPhone", function (v) {
  if (!v) { return "" }
  const s2 = ("" + v).replace(/\D/g, "")
  const m = s2.match(/^(\d{3})(\d{3})(\d{4})$/)
  return !m ? null : "(" + m[1] + ") " + m[2] + "-" + m[3]
})

Vue.filter("formatUnixDate", function (v) {
  if (!v) { return "" }
  return moment.unix(v).format("MMM DD, YYYY")
})

Vue.filter("formatDate", function (value) {
  if (!value) { return "" }
  const date = new Date(value)
  return moment.utc(date).format("MMM DD, YYYY")
})

Vue.filter("formatDateTime", function (value) {
  if (!value) { return "" }
  const date = new Date(value)
  return moment.utc(date).format("MMM DD, YYYY hh:mm:ss A")
})

Vue.filter("formatTime", function (value) {
  return value
})

Vue.filter("formatToFixed", function (value) {
  return value.toFixed(2)
})

Vue.filter("formatLocalDate", function (value) {
  if (!value) { return "" }
  const date = new Date(value)
  return moment
    .utc(date)
    .local()
    .format("MMM DD, YYYY")
})
Vue.filter("formatMoney", function (value) {
  if (!value) {
    return "---"
  } else {
    return (
      "$" + (value / 100).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    )
  }
})
Vue.filter("pluralize", function (n, w) {
  if (n === 1) {
    return w
  } else {
    return w + "s"
  }
})

Vue.filter("formatTime", function (value) {
  // return moment(value).format("h:mm A");
  return moment(value, ["h:mm A"]).format("h:mm A")
})

Vue.filter("ucFirst", v => !v ? "" : `${v.charAt(0).toUpperCase()}${v.slice(1)}`)
Vue.filter("formatUrl", url => url.includes("http") ? url : `http://${url}`)
