import createRepository from "~/modules/lms/api/LmsRepository.js"

// import moduleRepository from "~/api/moduleRepository.js";

export default (ctx, inject) => {
  const repositoryWithAxios = createRepository(ctx.$axios)

  const lmsrepositories = {
    lmsDirectives: repositoryWithAxios("directives"),
    lmsDirectivesLmsSubdirectives: repositoryWithAxios("directives", "subdirectives"),
    lmsBlocks: repositoryWithAxios("blocks"),
    lmsBlocksLmsBlocks: repositoryWithAxios("blocks", "subblocks"),
    lmsCourses: repositoryWithAxios("courses"),
    lmsCoursesPhotos: repositoryWithAxios("courses", "photos"),
    lmsCertificates: repositoryWithAxios("certificates"),
    lmsCoursesLmsFiles: repositoryWithAxios("courses", "files"),
    lmsCoursesLmsSections: repositoryWithAxios("courses", "sections"),
    lmsCoursesUsers: repositoryWithAxios("courses", "users"),
    lmsLessonsLmsBlocks: repositoryWithAxios("lessons", "blocks"),
    lmsFiles: repositoryWithAxios("files"),
    lmsLessons: repositoryWithAxios("lessons"),
    lmsQuizzes: repositoryWithAxios("quizzes"),
    lmsQuizzesLmsBlocks: repositoryWithAxios("quizzes", "blocks"),
    lmsSections: repositoryWithAxios("sections"),
    lmsSectionsLmsLessons: repositoryWithAxios("sections", "lessons"),
    // users: repositoryWithAxios("users"),
    // usersNotifications: repositoryWithAxios("users", "notifications"),
    // documents: repositoryWithAxios("documents"),
    lmsExpiringlinks: repositoryWithAxios("expiringlinks"),
    lmsFamilies: repositoryWithAxios("families"),
    expiringlinks: repositoryWithAxios("expiringlinks"),
    families: repositoryWithAxios("families"),
    lmsLessonsLmsBlocksOrder: repositoryWithAxios("lessons", "blocksOrder"),
    lmsCoursesLmsSectionsOrder: repositoryWithAxios("courses", "sectionsOrder"),
    lmsSectionsLmsLessonsOrder: repositoryWithAxios("sections", "lessonsOrder"),
    lmsBlocksLmsBlocksOrder: repositoryWithAxios("blocks", "blocksOrder"),
    lmsFilters: repositoryWithAxios("filters"),
    lmsTags: repositoryWithAxios("tags"),
    lmsFilterschemas: repositoryWithAxios("filterschemas"),
    userLmsCourses: repositoryWithAxios("users", "courses"),

    /** settings */
    lmsSectionschemas: repositoryWithAxios("sectionschemas"),
    // lmsForgotPassword: repositoryWithAxios("forgotPassword"),
    users: repositoryWithAxios("users")
    /** uploads */
  }

  inject("lmsrepositories", lmsrepositories)
}
