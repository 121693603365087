import axios from "axios"
import lmsActions from "../api/LmsActions.js"
import lmsMutations from "../api/LmsMutations.js"
import lmsGetters from "../api/LmsGetters.js"
export const namespaced = true

export const state = () => ({
  type: "users",
  pageLayout: "StructureTable",
  status: "",
  user: {},
  active: false,
  all: {},
  relations: [
    {
      name: "lmsCourses",
      value: "lmsCourses",
      translate: "courses",
      export: true
    },
    {
      name: "lmsCertificates",
      value: "lmsCertificates",
      translate: "certificates",
      export: false
    }
  ],
  reference: {},
  page: 1,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: null,
    sort: false,
    sortBy: "first_name",
    page: 1,
    sortDesc: []
  },
  crumbName: _ => `${_.first_name} ${_.last_name}`,
  total: 0,
  print: true,
  export: true,
  search: null,
  bound: {
    first_name: "",
    last_name: "",
    email: "",
    roles: "",
    permissions: "",
    can: ""
  },
  stub: {
    lmsCourses: []
  },
  filter: {},
  include: ["courses"],
  headers: {},
  notifications: {}
})

export const getters = {
  ...lmsGetters,
  user: state => state.user,
  stateTenant (state) {
    if (typeof state.user === "object" && state.user !== null) {
      //   if (state.user.roles.includes("state")) {
      //     return true
      //   } else {
      //     return false
      //   }
    } else {
      return false
    }
  }
  // isProfileLoaded: state => !!state.profile.name,
}

export const actions = {
  ...lmsActions,
  async userRequest ({ commit, dispatch }) {
    commit("userRequest")
    let response
    try {
      response = await axios.get("api/me")
    } catch (ex) {
      commit("userError", ex)
      dispatch("authLogout")
    }

    commit("userSuccess", response.data)
  }
}

export const mutations = {
  ...lmsMutations,
  userRequest: (state) => {
    state.status = "loading"
  },
  userSuccess: (state, payload) => {
    state.status = "success"
    state.user = payload.data
  },
  userError: (state, err) => {
    state.status = err
  }
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
