import lmsSections from "@/modules/lms/store/lmsSections.js"
import lmsDirectives from "@/modules/lms/store/lmsDirectives.js"
import lmsCertificates from "@/modules/lms/store/lmsCertificates.js"
import lmsFilterschemas from "@/modules/lms/store/lmsFilterschemas.js"
import lmsFiles from "@/modules/lms/store/lmsFiles.js"
import lmsFilters from "@/modules/lms/store/lmsFilters.js"
import lmsSystem from "@/modules/lms/store/lmsSystem.js"
import lmsTags from "@/modules/lms/store/lmsTags.js"
import lmsFamilies from "@/modules/lms/store/lmsFamilies.js"
import lmsExpiringlinks from "@/modules/lms/store/lmsExpiringlinks.js"
import users from "@/modules/lms/store/users.js"
import lmsLessons from "@/modules/lms/store/lmsLessons.js"
import lmsCourses from "@/modules/lms/store/lmsCourses.js"
import lmsBlocks from "@/modules/lms/store/lmsBlocks.js"
import lmsSectionschemas from "@/modules/lms/store/lmsSectionschemas.js"

export default ({ store }) => {
  store.registerModule("lmsSectionschemas", lmsSectionschemas)
  store.registerModule("lmsCourses", lmsCourses)
  store.registerModule("lmsCertificates", lmsCertificates)
  store.registerModule("lmsLessons", lmsLessons)
  store.registerModule("lmsBlocks", lmsBlocks)
  store.registerModule("lmsSections", lmsSections)
  store.registerModule("lmsDirectives", lmsDirectives)
  store.registerModule("lmsFilterschemas", lmsFilterschemas)
  store.registerModule("lmsFilters", lmsFilters)
  store.registerModule("lmsFiles", lmsFiles)
  store.registerModule("lmsSystem", lmsSystem)
  store.registerModule("lmsTags", lmsTags)
  store.registerModule("lmsFamilies", lmsFamilies)
  store.registerModule("lmsExpiringlinks", lmsExpiringlinks)
  store.registerModule("lmsUsers", users)
}
