// State ======================
import Vue from "vue";
import moment from "moment";
import globalActions from "../api/GlobalActions.js";
import globalMutations from "../api/GlobalMutations.js";
import globalGetters from "../api/GlobalGetters.js";

export const state = () => ( {
    type: "sections",
    relations: [
        { name: "lessons", value: "lessons" },
        { name: "courses", value: "courses" },
    ],
    all: {},
    reference: {
        /* for showing paginated resources ... needs a bit more work. */
    },
    active: false,
    page: 1,
    pageCount: 0,
    pagination: {
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
        itemsPerPage: 150,
        sort: true,
        sortBy: "name",
        page: 1,
        sortDesc: [],
    },
    total: 0,
    print: false,
    print_path: "",
    export: false,
    search: null,
    //this will need to by dynamic based on user config valuese
    bound: {},
    stub: {
        lessons: [],
        courses: [],
    },
    filter: {},
    //members are called contractors on the server
    include: "",
    headers: {},
    notifications: {},
} );

// Mutations ==================

export const mutations = {
    ...globalMutations,
    UpdateSectionTime(state, { userId, sectionId, time }) {
        Object.values(state.all).find(section => section.id == sectionId)["currenttime"] = time;
    },
    SET_VISITED(state, { courseId, sectionId }) {
        Object.values(state.all).find(section => section.id == sectionId)["visited"] = true;
    },
    SET_QUIZ_COMPLETED(state, { sectionId }) {
        Object.values(state.all).find(section => section.id == sectionId)["completed"] = true;
    },
    FLIP_SLIDER_PAGE(state, { sectionId, currentpage }) {
        const section = Object.values(state.all).find(section => section.id == sectionId)["currentpage"] = currentpage;
    }
};

// Actions ====================

export const actions = {
    ...globalActions,
    async updateSectionTime({ commit }, payload) {
        //Todo:: here send the info to the server

        await commit("UpdateSectionTime", payload)
    },
    async set_visited({ commit }, payload) {
        //Todo:: here send the info to the server

        commit("SET_VISITED", payload)
    },
    async post_quiz_answers({ commit }, payload) {
        //Todo:: here send the info to the server

        console.log({ payload })

        commit("SET_QUIZ_COMPLETED", payload);
    },
    async flip_slider_page({ commit }, payload) {
        //Todo:: here send the info to the server

        commit("FLIP_SLIDER_PAGE", payload);
    }
};

// Getters ====================

export const getters = {
    ...globalGetters,
};
