export const EditorCanvas = () => import('../../modules/lms/components/editor/main/EditorCanvas.vue' /* webpackChunkName: "components/editor-canvas" */).then(c => wrapFunctional(c.default || c))
export const EditorForm = () => import('../../modules/lms/components/editor/main/EditorForm.vue' /* webpackChunkName: "components/editor-form" */).then(c => wrapFunctional(c.default || c))
export const EditorLessons = () => import('../../modules/lms/components/editor/main/EditorLessons.vue' /* webpackChunkName: "components/editor-lessons" */).then(c => wrapFunctional(c.default || c))
export const EditorMain = () => import('../../modules/lms/components/editor/main/EditorMain.vue' /* webpackChunkName: "components/editor-main" */).then(c => wrapFunctional(c.default || c))
export const EditorPalette = () => import('../../modules/lms/components/editor/main/EditorPalette.vue' /* webpackChunkName: "components/editor-palette" */).then(c => wrapFunctional(c.default || c))
export const EditorSections = () => import('../../modules/lms/components/editor/main/EditorSections.vue' /* webpackChunkName: "components/editor-sections" */).then(c => wrapFunctional(c.default || c))
export const EditorSubBlock = () => import('../../modules/lms/components/editor/main/EditorSubBlock.vue' /* webpackChunkName: "components/editor-sub-block" */).then(c => wrapFunctional(c.default || c))
export const StdLessonBlock = () => import('../../modules/lms/components/lms/blocks/StdLessonBlock.vue' /* webpackChunkName: "components/std-lesson-block" */).then(c => wrapFunctional(c.default || c))
export const VideoAttach = () => import('../../modules/lms/components/lms/blocks/VideoAttach.vue' /* webpackChunkName: "components/video-attach" */).then(c => wrapFunctional(c.default || c))
export const CrumbtypeSelectionCard = () => import('../../modules/lms/components/lms/cards/CrumbtypeSelectionCard.vue' /* webpackChunkName: "components/crumbtype-selection-card" */).then(c => wrapFunctional(c.default || c))
export const LessonEditCard = () => import('../../modules/lms/components/lms/cards/LessonEditCard.vue' /* webpackChunkName: "components/lesson-edit-card" */).then(c => wrapFunctional(c.default || c))
export const SegmentEditCard = () => import('../../modules/lms/components/lms/cards/SegmentEditCard.vue' /* webpackChunkName: "components/segment-edit-card" */).then(c => wrapFunctional(c.default || c))
export const AllFilterCard = () => import('../../modules/lms/components/cards/AllFilterCard.vue' /* webpackChunkName: "components/all-filter-card" */).then(c => wrapFunctional(c.default || c))
export const EditSubBlocksCard = () => import('../../modules/lms/components/cards/EditSubBlocksCard.vue' /* webpackChunkName: "components/edit-sub-blocks-card" */).then(c => wrapFunctional(c.default || c))
export const ExpiringlinksCard = () => import('../../modules/lms/components/cards/ExpiringlinksCard.vue' /* webpackChunkName: "components/expiringlinks-card" */).then(c => wrapFunctional(c.default || c))
export const LmsBlocksCard = () => import('../../modules/lms/components/cards/LmsBlocksCard.vue' /* webpackChunkName: "components/lms-blocks-card" */).then(c => wrapFunctional(c.default || c))
export const LmsCoursesCard = () => import('../../modules/lms/components/cards/LmsCoursesCard.vue' /* webpackChunkName: "components/lms-courses-card" */).then(c => wrapFunctional(c.default || c))
export const LmsDirectiveCard = () => import('../../modules/lms/components/cards/LmsDirectiveCard.vue' /* webpackChunkName: "components/lms-directive-card" */).then(c => wrapFunctional(c.default || c))
export const LmsExpiringlinksCard = () => import('../../modules/lms/components/cards/LmsExpiringlinksCard.vue' /* webpackChunkName: "components/lms-expiringlinks-card" */).then(c => wrapFunctional(c.default || c))
export const LmsFilesCard = () => import('../../modules/lms/components/cards/LmsFilesCard.vue' /* webpackChunkName: "components/lms-files-card" */).then(c => wrapFunctional(c.default || c))
export const LmsLessonsCard = () => import('../../modules/lms/components/cards/LmsLessonsCard.vue' /* webpackChunkName: "components/lms-lessons-card" */).then(c => wrapFunctional(c.default || c))
export const LmsSectionsCard = () => import('../../modules/lms/components/cards/LmsSectionsCard.vue' /* webpackChunkName: "components/lms-sections-card" */).then(c => wrapFunctional(c.default || c))
export const LmsUsersCard = () => import('../../modules/lms/components/cards/LmsUsersCard.vue' /* webpackChunkName: "components/lms-users-card" */).then(c => wrapFunctional(c.default || c))
export const NotificationCard = () => import('../../modules/lms/components/cards/NotificationCard.vue' /* webpackChunkName: "components/notification-card" */).then(c => wrapFunctional(c.default || c))
export const QuizzesCard = () => import('../../modules/lms/components/cards/QuizzesCard.vue' /* webpackChunkName: "components/quizzes-card" */).then(c => wrapFunctional(c.default || c))
export const SaveFilterCard = () => import('../../modules/lms/components/cards/SaveFilterCard.vue' /* webpackChunkName: "components/save-filter-card" */).then(c => wrapFunctional(c.default || c))
export const CardBar = () => import('../../modules/lms/components/cards/cardBar.vue' /* webpackChunkName: "components/card-bar" */).then(c => wrapFunctional(c.default || c))
export const AttachDialog = () => import('../../modules/lms/components/create/AttachDialog.vue' /* webpackChunkName: "components/attach-dialog" */).then(c => wrapFunctional(c.default || c))
export const CreateCard = () => import('../../modules/lms/components/create/CreateCard.vue' /* webpackChunkName: "components/create-card" */).then(c => wrapFunctional(c.default || c))
export const CreateForm = () => import('../../modules/lms/components/create/CreateForm.vue' /* webpackChunkName: "components/create-form" */).then(c => wrapFunctional(c.default || c))
export const CreateLmsCard = () => import('../../modules/lms/components/create/CreateLmsCard.vue' /* webpackChunkName: "components/create-lms-card" */).then(c => wrapFunctional(c.default || c))
export const CreateLmsForm = () => import('../../modules/lms/components/create/CreateLmsForm.vue' /* webpackChunkName: "components/create-lms-form" */).then(c => wrapFunctional(c.default || c))
export const DeleteForm = () => import('../../modules/lms/components/create/DeleteForm.vue' /* webpackChunkName: "components/delete-form" */).then(c => wrapFunctional(c.default || c))
export const CrumbtypesCrumb = () => import('../../modules/lms/components/editor/crumbtypes/Crumb.vue' /* webpackChunkName: "components/crumbtypes-crumb" */).then(c => wrapFunctional(c.default || c))
export const CrumbtypesCrumbImage = () => import('../../modules/lms/components/editor/crumbtypes/CrumbImage.vue' /* webpackChunkName: "components/crumbtypes-crumb-image" */).then(c => wrapFunctional(c.default || c))
export const CrumbtypesCrumbText = () => import('../../modules/lms/components/editor/crumbtypes/CrumbText.vue' /* webpackChunkName: "components/crumbtypes-crumb-text" */).then(c => wrapFunctional(c.default || c))
export const CrumbtypesCrumbVideo = () => import('../../modules/lms/components/editor/crumbtypes/CrumbVideo.vue' /* webpackChunkName: "components/crumbtypes-crumb-video" */).then(c => wrapFunctional(c.default || c))
export const ExactBoolean = () => import('../../modules/lms/components/filter/ExactBoolean.vue' /* webpackChunkName: "components/exact-boolean" */).then(c => wrapFunctional(c.default || c))
export const ExactSelect = () => import('../../modules/lms/components/filter/ExactSelect.vue' /* webpackChunkName: "components/exact-select" */).then(c => wrapFunctional(c.default || c))
export const ExactText = () => import('../../modules/lms/components/filter/ExactText.vue' /* webpackChunkName: "components/exact-text" */).then(c => wrapFunctional(c.default || c))
export const FilterBar = () => import('../../modules/lms/components/filter/FilterBar.vue' /* webpackChunkName: "components/filter-bar" */).then(c => wrapFunctional(c.default || c))
export const FilterDate = () => import('../../modules/lms/components/filter/FilterDate.vue' /* webpackChunkName: "components/filter-date" */).then(c => wrapFunctional(c.default || c))
export const FilterDateRange = () => import('../../modules/lms/components/filter/FilterDateRange.vue' /* webpackChunkName: "components/filter-date-range" */).then(c => wrapFunctional(c.default || c))
export const FilterDayRange = () => import('../../modules/lms/components/filter/FilterDayRange.vue' /* webpackChunkName: "components/filter-day-range" */).then(c => wrapFunctional(c.default || c))
export const FilterDropdown = () => import('../../modules/lms/components/filter/FilterDropdown.vue' /* webpackChunkName: "components/filter-dropdown" */).then(c => wrapFunctional(c.default || c))
export const FilterPanel = () => import('../../modules/lms/components/filter/FilterPanel.vue' /* webpackChunkName: "components/filter-panel" */).then(c => wrapFunctional(c.default || c))
export const FilterRelationshipPanel = () => import('../../modules/lms/components/filter/FilterRelationshipPanel.vue' /* webpackChunkName: "components/filter-relationship-panel" */).then(c => wrapFunctional(c.default || c))
export const FilterSelector = () => import('../../modules/lms/components/filter/FilterSelector.vue' /* webpackChunkName: "components/filter-selector" */).then(c => wrapFunctional(c.default || c))
export const FilterSummary = () => import('../../modules/lms/components/filter/FilterSummary.vue' /* webpackChunkName: "components/filter-summary" */).then(c => wrapFunctional(c.default || c))
export const FilterToggle = () => import('../../modules/lms/components/filter/FilterToggle.vue' /* webpackChunkName: "components/filter-toggle" */).then(c => wrapFunctional(c.default || c))
export const FilterWith = () => import('../../modules/lms/components/filter/FilterWith.vue' /* webpackChunkName: "components/filter-with" */).then(c => wrapFunctional(c.default || c))
export const FilterWithOut = () => import('../../modules/lms/components/filter/FilterWithOut.vue' /* webpackChunkName: "components/filter-with-out" */).then(c => wrapFunctional(c.default || c))
export const FilterWithOutRel = () => import('../../modules/lms/components/filter/FilterWithOutRel.vue' /* webpackChunkName: "components/filter-with-out-rel" */).then(c => wrapFunctional(c.default || c))
export const FilterWithRel = () => import('../../modules/lms/components/filter/FilterWithRel.vue' /* webpackChunkName: "components/filter-with-rel" */).then(c => wrapFunctional(c.default || c))
export const FilterWithRelAtt = () => import('../../modules/lms/components/filter/FilterWithRelAtt.vue' /* webpackChunkName: "components/filter-with-rel-att" */).then(c => wrapFunctional(c.default || c))
export const FilterschemaAutocomplete = () => import('../../modules/lms/components/filter/FilterschemaAutocomplete.vue' /* webpackChunkName: "components/filterschema-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const FormattedFilter = () => import('../../modules/lms/components/filter/FormattedFilter.vue' /* webpackChunkName: "components/formatted-filter" */).then(c => wrapFunctional(c.default || c))
export const PowerFilter = () => import('../../modules/lms/components/filter/PowerFilter.vue' /* webpackChunkName: "components/power-filter" */).then(c => wrapFunctional(c.default || c))
export const DocumentsList = () => import('../../modules/lms/components/lists/DocumentsList.vue' /* webpackChunkName: "components/documents-list" */).then(c => wrapFunctional(c.default || c))
export const FilterAll = () => import('../../modules/lms/components/lists/FilterAll.vue' /* webpackChunkName: "components/filter-all" */).then(c => wrapFunctional(c.default || c))
export const FilterList = () => import('../../modules/lms/components/lists/FilterList.vue' /* webpackChunkName: "components/filter-list" */).then(c => wrapFunctional(c.default || c))
export const FilterMenu = () => import('../../modules/lms/components/lists/FilterMenu.vue' /* webpackChunkName: "components/filter-menu" */).then(c => wrapFunctional(c.default || c))
export const NotificationList = () => import('../../modules/lms/components/lists/NotificationList.vue' /* webpackChunkName: "components/notification-list" */).then(c => wrapFunctional(c.default || c))
export const RelationshipList = () => import('../../modules/lms/components/lists/RelationshipList.vue' /* webpackChunkName: "components/relationship-list" */).then(c => wrapFunctional(c.default || c))
export const RelationshipPrintList = () => import('../../modules/lms/components/lists/RelationshipPrintList.vue' /* webpackChunkName: "components/relationship-print-list" */).then(c => wrapFunctional(c.default || c))
export const SectionListGroup = () => import('../../modules/lms/components/lists/SectionListGroup.vue' /* webpackChunkName: "components/section-list-group" */).then(c => wrapFunctional(c.default || c))
export const SubList = () => import('../../modules/lms/components/lists/SubList.vue' /* webpackChunkName: "components/sub-list" */).then(c => wrapFunctional(c.default || c))
export const ColumnAction = () => import('../../modules/lms/components/table/ColumnAction.vue' /* webpackChunkName: "components/column-action" */).then(c => wrapFunctional(c.default || c))
export const ColumnActionText = () => import('../../modules/lms/components/table/ColumnActionText.vue' /* webpackChunkName: "components/column-action-text" */).then(c => wrapFunctional(c.default || c))
export const ColumnCheckbox = () => import('../../modules/lms/components/table/ColumnCheckbox.vue' /* webpackChunkName: "components/column-checkbox" */).then(c => wrapFunctional(c.default || c))
export const ColumnDate = () => import('../../modules/lms/components/table/ColumnDate.vue' /* webpackChunkName: "components/column-date" */).then(c => wrapFunctional(c.default || c))
export const ColumnDatetimeReadOnly = () => import('../../modules/lms/components/table/ColumnDatetimeReadOnly.vue' /* webpackChunkName: "components/column-datetime-read-only" */).then(c => wrapFunctional(c.default || c))
export const ColumnDropdown = () => import('../../modules/lms/components/table/ColumnDropdown.vue' /* webpackChunkName: "components/column-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ColumnExportType = () => import('../../modules/lms/components/table/ColumnExportType.vue' /* webpackChunkName: "components/column-export-type" */).then(c => wrapFunctional(c.default || c))
export const ColumnFamily = () => import('../../modules/lms/components/table/ColumnFamily.vue' /* webpackChunkName: "components/column-family" */).then(c => wrapFunctional(c.default || c))
export const ColumnHtml = () => import('../../modules/lms/components/table/ColumnHtml.vue' /* webpackChunkName: "components/column-html" */).then(c => wrapFunctional(c.default || c))
export const ColumnList = () => import('../../modules/lms/components/table/ColumnList.vue' /* webpackChunkName: "components/column-list" */).then(c => wrapFunctional(c.default || c))
export const ColumnMoney = () => import('../../modules/lms/components/table/ColumnMoney.vue' /* webpackChunkName: "components/column-money" */).then(c => wrapFunctional(c.default || c))
export const ColumnMoneyRaw = () => import('../../modules/lms/components/table/ColumnMoneyRaw.vue' /* webpackChunkName: "components/column-money-raw" */).then(c => wrapFunctional(c.default || c))
export const ColumnPaymentMember = () => import('../../modules/lms/components/table/ColumnPaymentMember.vue' /* webpackChunkName: "components/column-payment-member" */).then(c => wrapFunctional(c.default || c))
export const ColumnPhone = () => import('../../modules/lms/components/table/ColumnPhone.vue' /* webpackChunkName: "components/column-phone" */).then(c => wrapFunctional(c.default || c))
export const ColumnPrintCertification = () => import('../../modules/lms/components/table/ColumnPrintCertification.vue' /* webpackChunkName: "components/column-print-certification" */).then(c => wrapFunctional(c.default || c))
export const ColumnReadOnly = () => import('../../modules/lms/components/table/ColumnReadOnly.vue' /* webpackChunkName: "components/column-read-only" */).then(c => wrapFunctional(c.default || c))
export const ColumnRemoveSubDirective = () => import('../../modules/lms/components/table/ColumnRemoveSubDirective.vue' /* webpackChunkName: "components/column-remove-sub-directive" */).then(c => wrapFunctional(c.default || c))
export const ColumnRenew = () => import('../../modules/lms/components/table/ColumnRenew.vue' /* webpackChunkName: "components/column-renew" */).then(c => wrapFunctional(c.default || c))
export const ColumnStatus = () => import('../../modules/lms/components/table/ColumnStatus.vue' /* webpackChunkName: "components/column-status" */).then(c => wrapFunctional(c.default || c))
export const ColumnTagContact = () => import('../../modules/lms/components/table/ColumnTagContact.vue' /* webpackChunkName: "components/column-tag-contact" */).then(c => wrapFunctional(c.default || c))
export const ColumnTagContactPhone = () => import('../../modules/lms/components/table/ColumnTagContactPhone.vue' /* webpackChunkName: "components/column-tag-contact-phone" */).then(c => wrapFunctional(c.default || c))
export const ColumnText = () => import('../../modules/lms/components/table/ColumnText.vue' /* webpackChunkName: "components/column-text" */).then(c => wrapFunctional(c.default || c))
export const ColumnTimeReadOnly = () => import('../../modules/lms/components/table/ColumnTimeReadOnly.vue' /* webpackChunkName: "components/column-time-read-only" */).then(c => wrapFunctional(c.default || c))
export const ColumnUnregister = () => import('../../modules/lms/components/table/ColumnUnregister.vue' /* webpackChunkName: "components/column-unregister" */).then(c => wrapFunctional(c.default || c))
export const ColumnUser = () => import('../../modules/lms/components/table/ColumnUser.vue' /* webpackChunkName: "components/column-user" */).then(c => wrapFunctional(c.default || c))
export const WmsAction = () => import('../../modules/lms/components/table/wmsAction.vue' /* webpackChunkName: "components/wms-action" */).then(c => wrapFunctional(c.default || c))
export const PrintForm = () => import('../../modules/lms/components/upload/PrintForm.vue' /* webpackChunkName: "components/print-form" */).then(c => wrapFunctional(c.default || c))
export const UploadHeader = () => import('../../modules/lms/components/upload/UploadHeader.vue' /* webpackChunkName: "components/upload-header" */).then(c => wrapFunctional(c.default || c))
export const AttachBlock = () => import('../../modules/lms/components/lms/AttachBlock.vue' /* webpackChunkName: "components/attach-block" */).then(c => wrapFunctional(c.default || c))
export const AttachCallout = () => import('../../modules/lms/components/lms/AttachCallout.vue' /* webpackChunkName: "components/attach-callout" */).then(c => wrapFunctional(c.default || c))
export const AttachResource = () => import('../../modules/lms/components/lms/AttachResource.vue' /* webpackChunkName: "components/attach-resource" */).then(c => wrapFunctional(c.default || c))
export const AttachSingleBlock = () => import('../../modules/lms/components/lms/AttachSingleBlock.vue' /* webpackChunkName: "components/attach-single-block" */).then(c => wrapFunctional(c.default || c))
export const BlockPicker = () => import('../../modules/lms/components/lms/BlockPicker.vue' /* webpackChunkName: "components/block-picker" */).then(c => wrapFunctional(c.default || c))
export const CreateBlock = () => import('../../modules/lms/components/lms/CreateBlock.vue' /* webpackChunkName: "components/create-block" */).then(c => wrapFunctional(c.default || c))
export const CreateExistingBlock = () => import('../../modules/lms/components/lms/CreateExistingBlock.vue' /* webpackChunkName: "components/create-existing-block" */).then(c => wrapFunctional(c.default || c))
export const CreateObject = () => import('../../modules/lms/components/lms/CreateObject.vue' /* webpackChunkName: "components/create-object" */).then(c => wrapFunctional(c.default || c))
export const InsertBlock = () => import('../../modules/lms/components/lms/InsertBlock.vue' /* webpackChunkName: "components/insert-block" */).then(c => wrapFunctional(c.default || c))
export const ApiBlock = () => import('../../modules/lms/components/ApiBlock.js' /* webpackChunkName: "components/api-block" */).then(c => wrapFunctional(c.default || c))
export const ApiModel = () => import('../../modules/lms/components/ApiModel.js' /* webpackChunkName: "components/api-model" */).then(c => wrapFunctional(c.default || c))
export const AppDelete = () => import('../../modules/lms/components/AppDelete.vue' /* webpackChunkName: "components/app-delete" */).then(c => wrapFunctional(c.default || c))
export const AppOverlay = () => import('../../modules/lms/components/AppOverlay.vue' /* webpackChunkName: "components/app-overlay" */).then(c => wrapFunctional(c.default || c))
export const AppSubTable = () => import('../../modules/lms/components/AppSubTable.vue' /* webpackChunkName: "components/app-sub-table" */).then(c => wrapFunctional(c.default || c))
export const AppTable = () => import('../../modules/lms/components/AppTable.vue' /* webpackChunkName: "components/app-table" */).then(c => wrapFunctional(c.default || c))
export const AppToolbar = () => import('../../modules/lms/components/AppToolbar.vue' /* webpackChunkName: "components/app-toolbar" */).then(c => wrapFunctional(c.default || c))
export const ApplistItem = () => import('../../modules/lms/components/ApplistItem.js' /* webpackChunkName: "components/applist-item" */).then(c => wrapFunctional(c.default || c))
export const Back = () => import('../../modules/lms/components/Back.vue' /* webpackChunkName: "components/back" */).then(c => wrapFunctional(c.default || c))
export const BlockDialog = () => import('../../modules/lms/components/BlockDialog.vue' /* webpackChunkName: "components/block-dialog" */).then(c => wrapFunctional(c.default || c))
export const CompleteFilter = () => import('../../modules/lms/components/CompleteFilter.vue' /* webpackChunkName: "components/complete-filter" */).then(c => wrapFunctional(c.default || c))
export const DateMenu = () => import('../../modules/lms/components/DateMenu.vue' /* webpackChunkName: "components/date-menu" */).then(c => wrapFunctional(c.default || c))
export const DetachDialog = () => import('../../modules/lms/components/DetachDialog.vue' /* webpackChunkName: "components/detach-dialog" */).then(c => wrapFunctional(c.default || c))
export const DirectiveHeaders = () => import('../../modules/lms/components/DirectiveHeaders.vue' /* webpackChunkName: "components/directive-headers" */).then(c => wrapFunctional(c.default || c))
export const Download = () => import('../../modules/lms/components/Download.vue' /* webpackChunkName: "components/download" */).then(c => wrapFunctional(c.default || c))
export const DropdownMenu = () => import('../../modules/lms/components/DropdownMenu.vue' /* webpackChunkName: "components/dropdown-menu" */).then(c => wrapFunctional(c.default || c))
export const Export = () => import('../../modules/lms/components/Export.vue' /* webpackChunkName: "components/export" */).then(c => wrapFunctional(c.default || c))
export const ExportDialog = () => import('../../modules/lms/components/ExportDialog.vue' /* webpackChunkName: "components/export-dialog" */).then(c => wrapFunctional(c.default || c))
export const ExportNoColumns = () => import('../../modules/lms/components/ExportNoColumns.vue' /* webpackChunkName: "components/export-no-columns" */).then(c => wrapFunctional(c.default || c))
export const ExportRelations = () => import('../../modules/lms/components/ExportRelations.vue' /* webpackChunkName: "components/export-relations" */).then(c => wrapFunctional(c.default || c))
export const ExportSimple = () => import('../../modules/lms/components/ExportSimple.vue' /* webpackChunkName: "components/export-simple" */).then(c => wrapFunctional(c.default || c))
export const ExportSingle = () => import('../../modules/lms/components/ExportSingle.vue' /* webpackChunkName: "components/export-single" */).then(c => wrapFunctional(c.default || c))
export const FileAttachmentsReadOnly = () => import('../../modules/lms/components/FileAttachmentsReadOnly.vue' /* webpackChunkName: "components/file-attachments-read-only" */).then(c => wrapFunctional(c.default || c))
export const HeaderModal = () => import('../../modules/lms/components/HeaderModal.vue' /* webpackChunkName: "components/header-modal" */).then(c => wrapFunctional(c.default || c))
export const LmsSnack = () => import('../../modules/lms/components/LmsSnack.vue' /* webpackChunkName: "components/lms-snack" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../modules/lms/components/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const MediaManager = () => import('../../modules/lms/components/MediaManager.vue' /* webpackChunkName: "components/media-manager" */).then(c => wrapFunctional(c.default || c))
export const MediaUploader = () => import('../../modules/lms/components/MediaUploader.vue' /* webpackChunkName: "components/media-uploader" */).then(c => wrapFunctional(c.default || c))
export const NotificationDialog = () => import('../../modules/lms/components/NotificationDialog.vue' /* webpackChunkName: "components/notification-dialog" */).then(c => wrapFunctional(c.default || c))
export const Reveal = () => import('../../modules/lms/components/Reveal.vue' /* webpackChunkName: "components/reveal" */).then(c => wrapFunctional(c.default || c))
export const SelectChoice = () => import('../../modules/lms/components/SelectChoice.vue' /* webpackChunkName: "components/select-choice" */).then(c => wrapFunctional(c.default || c))
export const ShowDialog = () => import('../../modules/lms/components/ShowDialog.vue' /* webpackChunkName: "components/show-dialog" */).then(c => wrapFunctional(c.default || c))
export const Sort = () => import('../../modules/lms/components/Sort.vue' /* webpackChunkName: "components/sort" */).then(c => wrapFunctional(c.default || c))
export const StructureTable = () => import('../../modules/lms/components/StructureTable.vue' /* webpackChunkName: "components/structure-table" */).then(c => wrapFunctional(c.default || c))
export const SubExport = () => import('../../modules/lms/components/SubExport.vue' /* webpackChunkName: "components/sub-export" */).then(c => wrapFunctional(c.default || c))
export const Waves = () => import('../../modules/lms/components/waves.vue' /* webpackChunkName: "components/waves" */).then(c => wrapFunctional(c.default || c))
export const AnimatedGraphicSlide = () => import('../../components/blocks/AnimatedGraphicSlide.vue' /* webpackChunkName: "components/animated-graphic-slide" */).then(c => wrapFunctional(c.default || c))
export const Body = () => import('../../components/blocks/Body.vue' /* webpackChunkName: "components/body" */).then(c => wrapFunctional(c.default || c))
export const Brainbreakslide = () => import('../../components/blocks/Brainbreakslide.vue' /* webpackChunkName: "components/brainbreakslide" */).then(c => wrapFunctional(c.default || c))
export const CalloutOld = () => import('../../components/blocks/CalloutOld.vue' /* webpackChunkName: "components/callout-old" */).then(c => wrapFunctional(c.default || c))
export const Conent = () => import('../../components/blocks/Conent.vue' /* webpackChunkName: "components/conent" */).then(c => wrapFunctional(c.default || c))
export const Description = () => import('../../components/blocks/Description.vue' /* webpackChunkName: "components/description" */).then(c => wrapFunctional(c.default || c))
export const Econtinuum = () => import('../../components/blocks/Econtinuum.vue' /* webpackChunkName: "components/econtinuum" */).then(c => wrapFunctional(c.default || c))
export const Escalation = () => import('../../components/blocks/Escalation.vue' /* webpackChunkName: "components/escalation" */).then(c => wrapFunctional(c.default || c))
export const Flipcardslide = () => import('../../components/blocks/Flipcardslide.vue' /* webpackChunkName: "components/flipcardslide" */).then(c => wrapFunctional(c.default || c))
export const HeadingThree = () => import('../../components/blocks/HeadingThree.vue' /* webpackChunkName: "components/heading-three" */).then(c => wrapFunctional(c.default || c))
export const HeadingTwo = () => import('../../components/blocks/HeadingTwo.vue' /* webpackChunkName: "components/heading-two" */).then(c => wrapFunctional(c.default || c))
export const Headline = () => import('../../components/blocks/Headline.vue' /* webpackChunkName: "components/headline" */).then(c => wrapFunctional(c.default || c))
export const Introduction = () => import('../../components/blocks/Introduction.vue' /* webpackChunkName: "components/introduction" */).then(c => wrapFunctional(c.default || c))
export const JunoAudioEmbed = () => import('../../components/blocks/JunoAudioEmbed.vue' /* webpackChunkName: "components/juno-audio-embed" */).then(c => wrapFunctional(c.default || c))
export const JunoAudioImage = () => import('../../components/blocks/JunoAudioImage.vue' /* webpackChunkName: "components/juno-audio-image" */).then(c => wrapFunctional(c.default || c))
export const JunoBgImage = () => import('../../components/blocks/JunoBgImage.vue' /* webpackChunkName: "components/juno-bg-image" */).then(c => wrapFunctional(c.default || c))
export const JunoBlockquote = () => import('../../components/blocks/JunoBlockquote.vue' /* webpackChunkName: "components/juno-blockquote" */).then(c => wrapFunctional(c.default || c))
export const JunoBook = () => import('../../components/blocks/JunoBook.vue' /* webpackChunkName: "components/juno-book" */).then(c => wrapFunctional(c.default || c))
export const JunoBookAlt = () => import('../../components/blocks/JunoBookAlt.vue' /* webpackChunkName: "components/juno-book-alt" */).then(c => wrapFunctional(c.default || c))
export const JunoCallout = () => import('../../components/blocks/JunoCallout.vue' /* webpackChunkName: "components/juno-callout" */).then(c => wrapFunctional(c.default || c))
export const JunoCard = () => import('../../components/blocks/JunoCard.vue' /* webpackChunkName: "components/juno-card" */).then(c => wrapFunctional(c.default || c))
export const JunoCardSlide = () => import('../../components/blocks/JunoCardSlide.vue' /* webpackChunkName: "components/juno-card-slide" */).then(c => wrapFunctional(c.default || c))
export const JunoCards = () => import('../../components/blocks/JunoCards.vue' /* webpackChunkName: "components/juno-cards" */).then(c => wrapFunctional(c.default || c))
export const JunoClose = () => import('../../components/blocks/JunoClose.vue' /* webpackChunkName: "components/juno-close" */).then(c => wrapFunctional(c.default || c))
export const JunoFixedList = () => import('../../components/blocks/JunoFixedList.vue' /* webpackChunkName: "components/juno-fixed-list" */).then(c => wrapFunctional(c.default || c))
export const JunoFixedRight = () => import('../../components/blocks/JunoFixedRight.vue' /* webpackChunkName: "components/juno-fixed-right" */).then(c => wrapFunctional(c.default || c))
export const JunoFixedRightOld = () => import('../../components/blocks/JunoFixedRightOld.vue' /* webpackChunkName: "components/juno-fixed-right-old" */).then(c => wrapFunctional(c.default || c))
export const JunoFreeText = () => import('../../components/blocks/JunoFreeText.vue' /* webpackChunkName: "components/juno-free-text" */).then(c => wrapFunctional(c.default || c))
export const JunoGrounding = () => import('../../components/blocks/JunoGrounding.vue' /* webpackChunkName: "components/juno-grounding" */).then(c => wrapFunctional(c.default || c))
export const JunoImage = () => import('../../components/blocks/JunoImage.vue' /* webpackChunkName: "components/juno-image" */).then(c => wrapFunctional(c.default || c))
export const JunoLid = () => import('../../components/blocks/JunoLid.vue' /* webpackChunkName: "components/juno-lid" */).then(c => wrapFunctional(c.default || c))
export const JunoList = () => import('../../components/blocks/JunoList.vue' /* webpackChunkName: "components/juno-list" */).then(c => wrapFunctional(c.default || c))
export const JunoListOld = () => import('../../components/blocks/JunoListOld.vue' /* webpackChunkName: "components/juno-list-old" */).then(c => wrapFunctional(c.default || c))
export const JunoMindfullness = () => import('../../components/blocks/JunoMindfullness.vue' /* webpackChunkName: "components/juno-mindfullness" */).then(c => wrapFunctional(c.default || c))
export const JunoOrangeZone = () => import('../../components/blocks/JunoOrangeZone.vue' /* webpackChunkName: "components/juno-orange-zone" */).then(c => wrapFunctional(c.default || c))
export const JunoParallaxTitle = () => import('../../components/blocks/JunoParallaxTitle.vue' /* webpackChunkName: "components/juno-parallax-title" */).then(c => wrapFunctional(c.default || c))
export const JunoPlayAudio = () => import('../../components/blocks/JunoPlayAudio.vue' /* webpackChunkName: "components/juno-play-audio" */).then(c => wrapFunctional(c.default || c))
export const JunoPlayList = () => import('../../components/blocks/JunoPlayList.vue' /* webpackChunkName: "components/juno-play-list" */).then(c => wrapFunctional(c.default || c))
export const JunoRef = () => import('../../components/blocks/JunoRef.vue' /* webpackChunkName: "components/juno-ref" */).then(c => wrapFunctional(c.default || c))
export const JunoReflection = () => import('../../components/blocks/JunoReflection.vue' /* webpackChunkName: "components/juno-reflection" */).then(c => wrapFunctional(c.default || c))
export const JunoReflectionAlt = () => import('../../components/blocks/JunoReflectionAlt.vue' /* webpackChunkName: "components/juno-reflection-alt" */).then(c => wrapFunctional(c.default || c))
export const JunoReflectionOld = () => import('../../components/blocks/JunoReflectionOld.vue' /* webpackChunkName: "components/juno-reflection-old" */).then(c => wrapFunctional(c.default || c))
export const JunoScale = () => import('../../components/blocks/JunoScale.vue' /* webpackChunkName: "components/juno-scale" */).then(c => wrapFunctional(c.default || c))
export const JunoSectionTitle = () => import('../../components/blocks/JunoSectionTitle.vue' /* webpackChunkName: "components/juno-section-title" */).then(c => wrapFunctional(c.default || c))
export const JunoSingleReveal = () => import('../../components/blocks/JunoSingleReveal.vue' /* webpackChunkName: "components/juno-single-reveal" */).then(c => wrapFunctional(c.default || c))
export const JunoSlideBlockquote = () => import('../../components/blocks/JunoSlideBlockquote.vue' /* webpackChunkName: "components/juno-slide-blockquote" */).then(c => wrapFunctional(c.default || c))
export const JunoSlideCards = () => import('../../components/blocks/JunoSlideCards.vue' /* webpackChunkName: "components/juno-slide-cards" */).then(c => wrapFunctional(c.default || c))
export const JunoSlideLeft = () => import('../../components/blocks/JunoSlideLeft.vue' /* webpackChunkName: "components/juno-slide-left" */).then(c => wrapFunctional(c.default || c))
export const JunoStatic = () => import('../../components/blocks/JunoStatic.vue' /* webpackChunkName: "components/juno-static" */).then(c => wrapFunctional(c.default || c))
export const JunoStaticContent = () => import('../../components/blocks/JunoStaticContent.vue' /* webpackChunkName: "components/juno-static-content" */).then(c => wrapFunctional(c.default || c))
export const JunoStickyContent = () => import('../../components/blocks/JunoStickyContent.vue' /* webpackChunkName: "components/juno-sticky-content" */).then(c => wrapFunctional(c.default || c))
export const JunoStickyRight = () => import('../../components/blocks/JunoStickyRight.vue' /* webpackChunkName: "components/juno-sticky-right" */).then(c => wrapFunctional(c.default || c))
export const JunoStickySlide = () => import('../../components/blocks/JunoStickySlide.vue' /* webpackChunkName: "components/juno-sticky-slide" */).then(c => wrapFunctional(c.default || c))
export const JunoSubAudioImage = () => import('../../components/blocks/JunoSubAudioImage.vue' /* webpackChunkName: "components/juno-sub-audio-image" */).then(c => wrapFunctional(c.default || c))
export const JunoSubEditor = () => import('../../components/blocks/JunoSubEditor.vue' /* webpackChunkName: "components/juno-sub-editor" */).then(c => wrapFunctional(c.default || c))
export const JunoSubImage = () => import('../../components/blocks/JunoSubImage.vue' /* webpackChunkName: "components/juno-sub-image" */).then(c => wrapFunctional(c.default || c))
export const JunoSubLid = () => import('../../components/blocks/JunoSubLid.vue' /* webpackChunkName: "components/juno-sub-lid" */).then(c => wrapFunctional(c.default || c))
export const JunoSubNotebook = () => import('../../components/blocks/JunoSubNotebook.vue' /* webpackChunkName: "components/juno-sub-notebook" */).then(c => wrapFunctional(c.default || c))
export const JunoSubSvg = () => import('../../components/blocks/JunoSubSvg.vue' /* webpackChunkName: "components/juno-sub-svg" */).then(c => wrapFunctional(c.default || c))
export const JunoSubText = () => import('../../components/blocks/JunoSubText.vue' /* webpackChunkName: "components/juno-sub-text" */).then(c => wrapFunctional(c.default || c))
export const JunoSubVideo = () => import('../../components/blocks/JunoSubVideo.vue' /* webpackChunkName: "components/juno-sub-video" */).then(c => wrapFunctional(c.default || c))
export const JunoSubVideoAlt = () => import('../../components/blocks/JunoSubVideoAlt.vue' /* webpackChunkName: "components/juno-sub-video-alt" */).then(c => wrapFunctional(c.default || c))
export const JunoSvg = () => import('../../components/blocks/JunoSvg.vue' /* webpackChunkName: "components/juno-svg" */).then(c => wrapFunctional(c.default || c))
export const JunoText = () => import('../../components/blocks/JunoText.vue' /* webpackChunkName: "components/juno-text" */).then(c => wrapFunctional(c.default || c))
export const JunoTextAlt = () => import('../../components/blocks/JunoTextAlt.vue' /* webpackChunkName: "components/juno-text-alt" */).then(c => wrapFunctional(c.default || c))
export const JunoTextLeft = () => import('../../components/blocks/JunoTextLeft.vue' /* webpackChunkName: "components/juno-text-left" */).then(c => wrapFunctional(c.default || c))
export const JunoTextTitle = () => import('../../components/blocks/JunoTextTitle.vue' /* webpackChunkName: "components/juno-text-title" */).then(c => wrapFunctional(c.default || c))
export const JunoTwisiWrapper = () => import('../../components/blocks/JunoTwisiWrapper.vue' /* webpackChunkName: "components/juno-twisi-wrapper" */).then(c => wrapFunctional(c.default || c))
export const JunoVideoOnly = () => import('../../components/blocks/JunoVideoOnly.vue' /* webpackChunkName: "components/juno-video-only" */).then(c => wrapFunctional(c.default || c))
export const JunoVideoText = () => import('../../components/blocks/JunoVideoText.vue' /* webpackChunkName: "components/juno-video-text" */).then(c => wrapFunctional(c.default || c))
export const JunoWheel = () => import('../../components/blocks/JunoWheel.vue' /* webpackChunkName: "components/juno-wheel" */).then(c => wrapFunctional(c.default || c))
export const JunoWordCloud = () => import('../../components/blocks/JunoWordCloud.vue' /* webpackChunkName: "components/juno-word-cloud" */).then(c => wrapFunctional(c.default || c))
export const JunoZoneCard = () => import('../../components/blocks/JunoZoneCard.vue' /* webpackChunkName: "components/juno-zone-card" */).then(c => wrapFunctional(c.default || c))
export const Listreveal = () => import('../../components/blocks/Listreveal.vue' /* webpackChunkName: "components/listreveal" */).then(c => wrapFunctional(c.default || c))
export const Parallaxslide = () => import('../../components/blocks/Parallaxslide.vue' /* webpackChunkName: "components/parallaxslide" */).then(c => wrapFunctional(c.default || c))
export const Reflectionslide = () => import('../../components/blocks/Reflectionslide.vue' /* webpackChunkName: "components/reflectionslide" */).then(c => wrapFunctional(c.default || c))
export const Reflectionslideold = () => import('../../components/blocks/Reflectionslideold.vue' /* webpackChunkName: "components/reflectionslideold" */).then(c => wrapFunctional(c.default || c))
export const ResourceCallout = () => import('../../components/blocks/ResourceCallout.vue' /* webpackChunkName: "components/resource-callout" */).then(c => wrapFunctional(c.default || c))
export const StackofCards = () => import('../../components/blocks/StackofCards.vue' /* webpackChunkName: "components/stackof-cards" */).then(c => wrapFunctional(c.default || c))
export const Videoblock = () => import('../../components/blocks/Videoblock.vue' /* webpackChunkName: "components/videoblock" */).then(c => wrapFunctional(c.default || c))
export const Videoslide = () => import('../../components/blocks/Videoslide.vue' /* webpackChunkName: "components/videoslide" */).then(c => wrapFunctional(c.default || c))
export const Alllid = () => import('../../components/blocks/alllid.vue' /* webpackChunkName: "components/alllid" */).then(c => wrapFunctional(c.default || c))
export const Arc = () => import('../../components/blocks/arc.vue' /* webpackChunkName: "components/arc" */).then(c => wrapFunctional(c.default || c))
export const Childlid = () => import('../../components/blocks/childlid.vue' /* webpackChunkName: "components/childlid" */).then(c => wrapFunctional(c.default || c))
export const Continuum = () => import('../../components/blocks/continuum.vue' /* webpackChunkName: "components/continuum" */).then(c => wrapFunctional(c.default || c))
export const Halfimage = () => import('../../components/blocks/halfimage.vue' /* webpackChunkName: "components/halfimage" */).then(c => wrapFunctional(c.default || c))
export const Hand = () => import('../../components/blocks/hand.vue' /* webpackChunkName: "components/hand" */).then(c => wrapFunctional(c.default || c))
export const Lid = () => import('../../components/blocks/lid.vue' /* webpackChunkName: "components/lid" */).then(c => wrapFunctional(c.default || c))
export const LidDown = () => import('../../components/blocks/lidDown.vue' /* webpackChunkName: "components/lid-down" */).then(c => wrapFunctional(c.default || c))
export const Parentlid = () => import('../../components/blocks/parentlid.vue' /* webpackChunkName: "components/parentlid" */).then(c => wrapFunctional(c.default || c))
export const Test = () => import('../../components/blocks/test.vue' /* webpackChunkName: "components/test" */).then(c => wrapFunctional(c.default || c))
export const Violentlid = () => import('../../components/blocks/violentlid.vue' /* webpackChunkName: "components/violentlid" */).then(c => wrapFunctional(c.default || c))
export const CourseCard = () => import('../../components/cards/CourseCard.vue' /* webpackChunkName: "components/course-card" */).then(c => wrapFunctional(c.default || c))
export const PersonCard = () => import('../../components/cards/personCard.vue' /* webpackChunkName: "components/person-card" */).then(c => wrapFunctional(c.default || c))
export const BaseSlide = () => import('../../components/forms/BaseSlide.vue' /* webpackChunkName: "components/base-slide" */).then(c => wrapFunctional(c.default || c))
export const CourseList = () => import('../../components/lists/CourseList.vue' /* webpackChunkName: "components/course-list" */).then(c => wrapFunctional(c.default || c))
export const DeleteLessonList = () => import('../../components/lists/DeleteLessonList.vue' /* webpackChunkName: "components/delete-lesson-list" */).then(c => wrapFunctional(c.default || c))
export const LessonList = () => import('../../components/lists/LessonList.vue' /* webpackChunkName: "components/lesson-list" */).then(c => wrapFunctional(c.default || c))
export const SectionsList = () => import('../../components/lists/SectionsList.vue' /* webpackChunkName: "components/sections-list" */).then(c => wrapFunctional(c.default || c))
export const JunoLesson = () => import('../../components/lessonTypes/JunoLesson.vue' /* webpackChunkName: "components/juno-lesson" */).then(c => wrapFunctional(c.default || c))
export const QuizLesson = () => import('../../components/lessonTypes/QuizLesson.vue' /* webpackChunkName: "components/quiz-lesson" */).then(c => wrapFunctional(c.default || c))
export const SlideshowLesson1 = () => import('../../components/lessonTypes/SlideshowLesson_1.vue' /* webpackChunkName: "components/slideshow-lesson1" */).then(c => wrapFunctional(c.default || c))
export const SlideshowLessonStable = () => import('../../components/lessonTypes/SlideshowLesson_stable.vue' /* webpackChunkName: "components/slideshow-lesson-stable" */).then(c => wrapFunctional(c.default || c))
export const SlideshowLessonTemp = () => import('../../components/lessonTypes/SlideshowLesson_temp.vue' /* webpackChunkName: "components/slideshow-lesson-temp" */).then(c => wrapFunctional(c.default || c))
export const TextLesson = () => import('../../components/lessonTypes/TextLesson.vue' /* webpackChunkName: "components/text-lesson" */).then(c => wrapFunctional(c.default || c))
export const VideoLesson = () => import('../../components/lessonTypes/VideoLesson.vue' /* webpackChunkName: "components/video-lesson" */).then(c => wrapFunctional(c.default || c))
export const ActiveDialog = () => import('../../components/ActiveDialog.vue' /* webpackChunkName: "components/active-dialog" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../../components/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const FullScreenSlider = () => import('../../components/FullScreenSlider.vue' /* webpackChunkName: "components/full-screen-slider" */).then(c => wrapFunctional(c.default || c))
export const GenerateCertificate = () => import('../../components/GenerateCertificate.vue' /* webpackChunkName: "components/generate-certificate" */).then(c => wrapFunctional(c.default || c))
export const Iafsc = () => import('../../components/Iafsc.vue' /* webpackChunkName: "components/iafsc" */).then(c => wrapFunctional(c.default || c))
export const IconBase = () => import('../../components/IconBase.vue' /* webpackChunkName: "components/icon-base" */).then(c => wrapFunctional(c.default || c))
export const ImageSlide = () => import('../../components/ImageSlide.vue' /* webpackChunkName: "components/image-slide" */).then(c => wrapFunctional(c.default || c))
export const Object = () => import('../../components/Object.vue' /* webpackChunkName: "components/object" */).then(c => wrapFunctional(c.default || c))
export const ResourceDrawer = () => import('../../components/ResourceDrawer.vue' /* webpackChunkName: "components/resource-drawer" */).then(c => wrapFunctional(c.default || c))
export const Search = () => import('../../components/Search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayer = () => import('../../components/VideoPlayer.vue' /* webpackChunkName: "components/video-player" */).then(c => wrapFunctional(c.default || c))
export const Voice = () => import('../../components/Voice.vue' /* webpackChunkName: "components/voice" */).then(c => wrapFunctional(c.default || c))
export const Voices = () => import('../../components/Voices.vue' /* webpackChunkName: "components/voices" */).then(c => wrapFunctional(c.default || c))
export const Contents = () => import('../../components/contents.vue' /* webpackChunkName: "components/contents" */).then(c => wrapFunctional(c.default || c))
export const ExampleOne = () => import('../../components/exampleOne.vue' /* webpackChunkName: "components/example-one" */).then(c => wrapFunctional(c.default || c))
export const ExampleTwo = () => import('../../components/exampleTwo.vue' /* webpackChunkName: "components/example-two" */).then(c => wrapFunctional(c.default || c))
export const FeatureSection = () => import('../../components/featureSection.vue' /* webpackChunkName: "components/feature-section" */).then(c => wrapFunctional(c.default || c))
export const Flipcard = () => import('../../components/flipcard.vue' /* webpackChunkName: "components/flipcard" */).then(c => wrapFunctional(c.default || c))
export const General = () => import('../../components/general.vue' /* webpackChunkName: "components/general" */).then(c => wrapFunctional(c.default || c))
export const Guide = () => import('../../components/guide.vue' /* webpackChunkName: "components/guide" */).then(c => wrapFunctional(c.default || c))
export const Iafschild = () => import('../../components/iafschild.vue' /* webpackChunkName: "components/iafschild" */).then(c => wrapFunctional(c.default || c))
export const Navigation = () => import('../../components/navigation.vue' /* webpackChunkName: "components/navigation" */).then(c => wrapFunctional(c.default || c))
export const Overview = () => import('../../components/overview.vue' /* webpackChunkName: "components/overview" */).then(c => wrapFunctional(c.default || c))
export const Pageexample = () => import('../../components/pageexample.vue' /* webpackChunkName: "components/pageexample" */).then(c => wrapFunctional(c.default || c))
export const PresentationEngine = () => import('../../components/presentationEngine.vue' /* webpackChunkName: "components/presentation-engine" */).then(c => wrapFunctional(c.default || c))
export const RoadBlock = () => import('../../components/roadBlock.vue' /* webpackChunkName: "components/road-block" */).then(c => wrapFunctional(c.default || c))
export const SlideWrapper = () => import('../../components/slideWrapper.vue' /* webpackChunkName: "components/slide-wrapper" */).then(c => wrapFunctional(c.default || c))
export const WysiwygHighlight = () => import('../../components/wysiwygHighlight.vue' /* webpackChunkName: "components/wysiwyg-highlight" */).then(c => wrapFunctional(c.default || c))
export const QuizTypesMultiselect = () => import('../../components/quizTypes/Multiselect.vue' /* webpackChunkName: "components/quiz-types-multiselect" */).then(c => wrapFunctional(c.default || c))
export const QuizTypesOptions = () => import('../../components/quizTypes/Options.vue' /* webpackChunkName: "components/quiz-types-options" */).then(c => wrapFunctional(c.default || c))
export const QuizTypesTextbox = () => import('../../components/quizTypes/Textbox.vue' /* webpackChunkName: "components/quiz-types-textbox" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
