// State ======================
import lmsActions from "../api/LmsActions.js"
import lmsMutations from "../api/LmsMutations.js"
import lmsGetters from "../api/LmsGetters.js"
export const namespaced = true

export const state = () => ({
  type: "lmsFiles",
  relations: [
    {
      name: "lmsTags",
      value: "lmsTags",
      translate: "tags",
      export: false
    }
  ],
  all: {},
  reference: {},
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 20,
    sort: true,
    sortBy: "filename",
    page: 1,
    descending: false,
    sortDesc: []
  },
  total: 0,
  print: false,
  print_path: "",
  export: false,
  search: null,
  bound: {},
  stub: {
    lmsTags: []
  },
  filter: {},
  include: [],
  headers: {},
  notifications: {}
})

// Mutations ==================

export const mutations = {
  ...lmsMutations
}

// Actions ====================

export const actions = {
  ...lmsActions
}

// Getters ====================

export const getters = {
  ...lmsGetters
}
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
