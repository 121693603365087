export const namespaced = true;

export const state = () => ({
  snack: "",
  snackSettings: {
    color: "secondary",
    top: false,
    timeout: 4000,
    right: true,
  },
  activeView: false,
  pastView: [],
  cart: [],
  validateFields: {},
  showFormErrors: false,
  //This should probably be moved to keep the store clean.  I'm just not sure where ATM.
  portalUserName: "",
  tenant: false,
  loading: false,
  // impersonating: false,
  // client_token: null,
  // admin_token: null
});
export const actions = {
  setLoading({ commit, state }, status) {
    commit("updateLoadingStatus", status);
  },
  active_view({ commit, state }, payload) {
    commit("SET_ACTIVE_VIEW", payload.view);
    commit("SET_HISTORY", payload);
  },
  async navBack({ commit, state, getters, dispatch, rootState }) {
    let new_active = state.pastView[1];
    await dispatch(`${new_active.view}/set_active`, new_active.id, {
      root: true,
    });
    await commit("NAVIGATE_ACTIVE_DIALOG_BACK");
  },

  async resetPassword({ commit, state }, payload) {
    const results = await this.$repositories["forgotPassword"].create(payload);
    return results;
  },
};
export const mutations = {
  NAVIGATE_ACTIVE_DIALOG_BACK(state) {
    //state.pastView.shift();
    state.pastView.splice(0, 2);
  },
  SET_SNACK(state, snack) {
    state.snack = snack;
  },
  updateLoadingStatus(state, status) {
    state.loading = status;
  },
  SET_ACTIVE_VIEW(state, active) {
    //name of module is passed here

    state.activeView = active;
  },
  SET_HISTORY(state, payload) {
    state.pastView.unshift(payload);
  },
  FlUSH_ACTIVE_VIEW(state, active) {
    //name of module is passed here
    state.activeView = false;
  },
  FLUSH_HISTORY(state) {
    state.pastView = [];
  },
  set_snackSettings: (state, snackSettings) => {
    state.snackSettings = snackSettings;
  },

  set_validateFields: (state, validateFields) => {
    state.validateFields = validateFields;
  },
  ADD_TO_CART: (state, payload) => {
    let exists = state.cart.some((item) => {
      return item.id === payload.id;
    });
    if (exists) {
      let index = state.cart.indexOf(payload.id);
      state.cart.splice(index, 1);
      state.cart.push(payload);
    } else {
      state.cart.push(payload);
    }
  },
  CLEAR_CART: (state) => {
    state.cart = [];
  },

  set_showFormErrors: (state, showFormErrors) => {
    state.showFormErrors = showFormErrors;
  },
  savePortalUser: (state, data) => {
    state.portalUserName = data;
  },
  savePortalTenant: (state, data) => {
    state.tenant = data;
  },
};
export const getters = {
  cartCount(state) {
    let cart = state.cart;
    let x = 0;
    cart.forEach((el) => {
      x = x + +el.qty;
    });

    return x;
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
