import { filterRelationLabels, resolveFilters, resolveSavedFilters } from "../config/savedFilters"
import { resolveRelations } from "./Helpers"

export default {
  find: (state, getters, rootState) => (id) => {
    return state.all[id]
      ? resolveRelations(state.all[id], state.relations, rootState)
      : false
  },

  active (state, getters, rootState) {
    return state.active
      ? resolveRelations(state.all[state.active], state.relations, rootState)
      : false
  },
  allHeaders (state) {
    const headers = Object.values(state.headers)
    const filtered = headers.filter(function (obj) {
      return obj.used
    })
    return filtered
  },

  visibleHeaders (state) {
    const headers = Object.values(state.headers)
    const filtered = headers.filter(function (obj) {
      return obj.display && obj.used
    })
    return filtered
  },
  publicHeaders (state) {
    const headers = Object.values(state.headers)
    const filtered = headers.filter(function (obj) {
      return obj.display && obj.used && obj.protected
    })
    return filtered
  },
  tableHeaders (state) {
    const headers = Object.values(state.headers)
    const filtered = headers.filter(function (obj) {
      return obj.tableDisplay && obj.used
    })

    const ordered = filtered.sort(function (a, b) {
      return a.column - b.column
    })
    return ordered
  },
  createHeaders (state) {
    const headers = Object.values(state.headers)
    const filtered = headers.filter(function (obj) {
      if (obj.hasOwnProperty("createDisplay")) {
        return obj.createDisplay && obj.used
      } else {
        return obj.tableDisplay && obj.used
      }
    })

    const ordered = filtered.sort(function (a, b) {
      return a.column - b.column
    })
    return ordered
  },
  reportList (state) {},
  exportAttributes (state) {
    const relationships = state.relations.map(({ name }) => name)

    const headers = Object.values(state.headers)
    const filtered = headers.filter(function (obj) {
      return (
        obj.used && obj.createDisplay && !relationships.includes(obj.field)
      )
    })

    const ordered = filtered.sort(function (a, b) {
      return a.column - b.column
    })
    return ordered
  },
  filterAttributes (state) {
    const relationships = state.relations.map(({ name }) => name)

    const headers = Object.values(state.headers)

    const filtered = headers.filter(function (obj) {
      return (
        obj.used && obj.filterDisplay && !relationships.includes(obj.field)
      )
    })

    return filtered.sort(function (a, b) {
      return a.column - b.column
    })
  },
  filterRelations (state) {
    const headers = Object.values(state.relations)
    const filtered = headers.filter(function (obj) {
      return (
        obj.name !== "payments" &&
        obj.name !== "photos" &&
        obj.name !== "tasks" &&
        obj.name !== "documents" &&
        obj.name !== "profile" &&
        obj.name !== "notes"
      )
    })
    return filtered.map(({ name }) => name)
  },
  exportRelations (state) {
    return state.relations.filter(function (obj) {
      return obj.export
    })
  },
  filter (state) {
    return state.filter
  },
  params (state) {
    const x = {}
    const { sortBy, descending } = state.pagination
    let sort = sortBy
    if (descending) {
      sort = "-" + sortBy
      x.sort = sort
    } else {
      x.sort = sort
    }

    x.page = state.page
    if (state.pagination.itemsPerPage) {
      x.limit = state.pagination.itemsPerPage
    }

    if (state.search) {
      const searchTerm = "filter_scope[search]"
      x[searchTerm] = state.search
    }

    // used to add in filter relations
    const relationsToinclude = []

    const filter = state.filter
    for (const prop in filter) {
      if (prop === "exact") {
        Object.entries(filter[prop]).forEach(([key, value]) => {
          const exact = `filter_exact[${key}]`
          const arr = []
          let formattedValue

          if (typeof value === "object") {
            value = Object.values(value)
          }

          if (value.isArray) {
            value.forEach((v) => {
              arr.push(encodeURIComponent(v))
            })
            formattedValue = arr.join(",")
          } else {
            formattedValue = value
          }

          // if (typeof value === "boolean") {
          //   formattedValue = value;
          // } else {
          //   value.forEach(v => {
          //     arr.push(encodeURIComponent(v));
          //   });
          //   formattedValue = arr.join(",");
          // }
          x[exact] = formattedValue
        })
      } else if (prop === "relations") {
        // add each of these relations to the include!
        const without = []
        Object.entries(filter[prop]).forEach(([key, value]) => {
          const arr = []

          const filterLabel = state.relations.find((obj) => {
            return obj.name === key
          })
          relationsToinclude.push(filterLabel.translate)
          const exactRelation = `filter_exact[${filterLabel.translate}.id]`
          // const v = Object.values(value)
          for (const [k, val] of Object.entries(value)) {
            if (k === "with_rel") {
              const v = Object.values(val)
              v.forEach((item) => {
                arr.push(encodeURIComponent(item))
              })
              const formattedValue = arr.join(",")
              x[exactRelation] = formattedValue
            } else if (k === "without_rel") {
              const filterLabel = state.relations.find((obj) => {
                return obj.name === key
              })
              // const scopeName = `filter_scope[${k}]`
              // Object.entries(v).forEach(([k, v]) => {
              //   without.push(encodeURIComponent(v));
              // });

              const formattedScope = filterLabel.translate
              without.push(encodeURIComponent(formattedScope))

              // x[scopeName] = [];
            } else {
              const filterRelation = state.relations.find((obj) => {
                return obj.name === key
              })
              // let scopeName = `filter_scope[${k}]`;
              Object.entries(val).forEach(([k, v]) => {
                //
                const exact = `filter_exact[${filterRelation.translate}.${k}]`
                const arr = []
                let formattedValue

                if (typeof v === "object") {
                  v = Object.values(v)
                }

                if (v.isArray) {
                  v.forEach((val) => {
                    arr.push(encodeURIComponent(val))
                  })
                  formattedValue = arr.join(",")
                } else {
                  formattedValue = v
                }

                // if (typeof value === "boolean") {
                //   formattedValue = value;
                // } else {
                //   value.forEach(v => {
                //     arr.push(encodeURIComponent(v));
                //   });
                //   formattedValue = arr.join(",");
                // }
                x[exact] = formattedValue

                //

                // scope.push(encodeURIComponent(v));
              })
              // let formattedScope = filterLabel.translate;
              // x[scopeName] = formattedScope;
            }
          }
        })
        if (without.length) {
          const formattedWithoutRelation = without.join("|")
          x["filter_scope[without_rel]"] = formattedWithoutRelation
        }
      } else {
        const scope = `filter_scope[${prop}]`
        const arr = []
        // const values = []<
        let formattedValue = ""

        if (Array.isArray(filter[prop])) {
          arr.push(...filter[prop])
        } else if (typeof filter[prop] === "string") {
          arr.push(filter[prop])
        } else if (filter[prop]) {
          Object.entries(filter[prop]).forEach(([key, value]) => {
            arr.push(key)

            if (typeof value === "boolean" || typeof value === "string") {
              arr.push(value)
            } else {
              Object.entries(value).forEach(([k, v]) => {
                arr.push(encodeURIComponent(v))
              })
            }
          })
        }

        formattedValue = arr.join(",")

        x[scope] = formattedValue
      }
    }
    if (state.include.length) {
      x.include = state.include.concat(relationsToinclude).join(",")
    }
    return x
  },
  quickfilters (state) {
    return resolveSavedFilters(state.type)
  },
  formattedFilters (state) {
    const filter = _.omit(state.filter, "relations")
    const list = []

    // loop filters
    // check if it is relations -> do relation things.
    // if not relations, run loop
    // for each
    // if exact push values
    // else resolve filter
    // create obj

    _.forEach(filter, function (v, k) {
      if (k === "exact") {
        Object.entries(v).forEach(([key, value]) => {
          let formattedValue = ""
          if (typeof value === "boolean") {
            formattedValue = value
          } else {
            const entryValue = []
            Object.entries(value).forEach(([y, x]) => {
              entryValue.push(x)
            })
            formattedValue = entryValue.join(",")
          }

          const obj = {
            value: formattedValue,
            type: k,
            key,
            relation: false,
            relationpath: ""
          }
          list.push(obj)
        })
      } else {
        Object.entries(v).forEach(([key, value]) => {
          const obj = {
            value: resolveFilters(k, Object.values(value)),
            type: k,
            key,
            relation: false,
            relationpath: ""
          }
          list.push(obj)
        })
      }
    })
    return list
  },

  formattedRelationFilters (state, getters, rootState, rootGetters) {
    if (state.filter.hasOwnProperty("relations")) {
      const list = []
      const relations = state.filter.relations
      const filterRelationLabel = filterRelationLabels()

      _.forEach(relations, function (r, rk) {
        const relationName = rk

        const mappedPath = filterRelationLabel.find(
          (obj) => {
            return obj.field === relationName
          }
        )

        _.forEach(r, function (v, k) {
          if (k === "with_rel" || k === "exact") {
            Object.entries(v).forEach(([key, value]) => {
              let formattedValue = ""

              if (typeof value === "boolean") {
                formattedValue = value
              } else {
                const temp = rootGetters[`${mappedPath.model}/find`](value)
                if (temp) {
                  const relationSchema = filterRelationLabel.find(
                    obj => obj.field === relationName
                  )

                  formattedValue = temp[relationSchema.itemText]
                } else {
                  formattedValue = value
                }
              }

              const obj = {
                value: formattedValue,
                type: k,
                key,
                relation: true,
                relationpath: relationName
              }
              list.push(obj)
            })
          } else if (k === "without_rel") {
            const obj = {
              value: "Missing",
              type: k,
              key: relationName,
              relation: true,
              relationpath: relationName
            }
            list.push(obj)
          } else {
            Object.entries(v).forEach(([key, value]) => {
              const obj = {
                value: resolveFilters(k, Object.values(value)),
                type: k,
                key,
                relation: true,
                relationpath: relationName
              }
              list.push(obj)
            })
          }
        })
      })
      return list
    } else {
      return {}
    }
  }
}
