
export default {
  name: "Student",
  data () {
    return {
      drawer: false
    }
  },
  async fetch ({ store }) {
    await store.dispatch("users/show", store.state.auth.user.data.id)
    await store.dispatch("courses/list")
  },
  computed: {
    showBanner () {
      return this.$route.name === "index"
    },
    isFluid () {
      return true
    //   return !!this.$store.state.system.is_layout_fluid;
    }
  },
  methods: {
    logout () {
      this.$auth.logout().then(() => this.$router.push("/login"))
    }
  }
}
