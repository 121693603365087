import Auth from './auth'

import './middleware'

// Active schemes
import scheme_003d9a64 from './schemes/local.js'
import scheme_5b406f0d from './schemes/PassportPasswordScheme.js'
import scheme_23514a38 from './schemes/oauth2.js'

export default function (ctx, inject) {
  // Options
  const options = {"resetOnError":false,"scopeKey":"scope","rewriteRedirects":true,"fullPathRedirect":false,"watchLoggedIn":true,"redirect":{"login":"/signup","logout":"/","home":"/","callback":"/login","user":"/"},"vuex":{"namespace":"auth"},"cookie":{"prefix":"auth.","options":{"path":"/"}},"localStorage":{"prefix":"auth."},"token":{"prefix":"_token."},"refresh_token":{"prefix":"_refresh_token."},"defaultStrategy":"local"}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // local
  $auth.registerStrategy('local', new scheme_003d9a64($auth, {"endpoints":{"login":{"url":"/api/auth/login","method":"post","propertyName":"token"},"logout":{"url":"/api/auth/logout","method":"post"},"user":{"url":"/api/auth/user","method":"get","propertyName":"user"}},"_name":"local"}))

  // password_grant
  $auth.registerStrategy('password_grant', new scheme_003d9a64($auth, {"endpoints":{"login":{"url":"/oauth/token","method":"post","propertyName":"access_token"},"logout":false,"user":{"url":"api/me"}},"_name":"password_grant"}))

  // password_grant_custom
  $auth.registerStrategy('password_grant_custom', new scheme_5b406f0d($auth, {"client_id":"5","client_secret":"XhQZRCATxssc9FP3nLeslsChsxQptQDRJVa1DRWr","endpoints":{"login":{"url":"/oauth/token","method":"post","propertyName":"access_token"},"logout":false,"user":{"url":"api/me"}},"_name":"password_grant_custom"}))

  // laravel.passport
  $auth.registerStrategy('laravel.passport', new scheme_23514a38($auth, {"url":"https://ia.helenaedge.com","userinfo_endpoint":"https://ia.helenaedge.com/api/me","_name":"laravel.passport","authorization_endpoint":"https://ia.helenaedge.com/oauth/authorize","token_endpoint":"https://ia.helenaedge.com/oauth/token","token_key":"access_token","token_type":"Bearer","response_type":"code","grant_type":"authorization_code","scope":"*","access_token_endpoint":"/_auth/oauth/laravel.passport/authorize"}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      console.error('[ERROR] [AUTH]', error)
    }
  })
}
