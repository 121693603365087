import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _07d4f574 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _e2477afc = () => interopDefault(import('../pages/Avatar.vue' /* webpackChunkName: "pages/Avatar" */))
const _8803ef32 = () => interopDefault(import('../pages/forgotPassword.vue' /* webpackChunkName: "pages/forgotPassword" */))
const _b9774720 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _99e50824 = () => interopDefault(import('../pages/old.vue' /* webpackChunkName: "pages/old" */))
const _1a345ade = () => interopDefault(import('../pages/resetPassword.vue' /* webpackChunkName: "pages/resetPassword" */))
const _11a11ea1 = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _e6ede84a = () => interopDefault(import('../pages/families/access/index.vue' /* webpackChunkName: "pages/families/access/index" */))
const _a725f804 = () => interopDefault(import('../pages/families/edit.vue' /* webpackChunkName: "pages/families/edit" */))
const _02c78163 = () => interopDefault(import('../pages/families/old.vue' /* webpackChunkName: "pages/families/old" */))
const _4cc68d4e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _a0644306 = () => interopDefault(import('../pages/courses/_slug/index.vue' /* webpackChunkName: "pages/courses/_slug/index" */))
const _1ef2d979 = () => interopDefault(import('../pages/families/_id/index.vue' /* webpackChunkName: "pages/families/_id/index" */))
const _1b98a29e = () => interopDefault(import('../pages/courses/_slug/overview.vue' /* webpackChunkName: "pages/courses/_slug/overview" */))
const _e0c828d8 = () => interopDefault(import('../pages/families/_id/reflection.vue' /* webpackChunkName: "pages/families/_id/reflection" */))
const _92570dfa = () => interopDefault(import('../pages/families/_id/training.vue' /* webpackChunkName: "pages/families/_id/training" */))
const _332461f7 = () => interopDefault(import('../pages/courses/_slug/lessons/lessonId_temp.vue' /* webpackChunkName: "pages/courses/_slug/lessons/lessonId_temp" */))
const _0bd22629 = () => interopDefault(import('../pages/courses/_slug/lessons/_lessonId.vue' /* webpackChunkName: "pages/courses/_slug/lessons/_lessonId" */))
const _5460fa28 = () => interopDefault(import('../pages/courses/_slug/lessons/lessonId.vue' /* webpackChunkName: "pages/courses/_slug/lessons/lessonId" */))
const _49646856 = () => interopDefault(import('~/modules/lms/pages/lmsCourses.vue' /* webpackChunkName: "" */))
const _15e55e98 = () => interopDefault(import('~/modules/lms/pages/lmsCourses/index.vue' /* webpackChunkName: "" */))
const _630a81b4 = () => interopDefault(import('~/modules/lms/pages/lmsCourses/create.vue' /* webpackChunkName: "" */))
const _37ea2700 = () => interopDefault(import('~/modules/lms/pages/lmsCourses/_id.vue' /* webpackChunkName: "" */))
const _7a4c293e = () => interopDefault(import('~/modules/lms/pages/lmsCourses/_id/chapter.vue' /* webpackChunkName: "" */))
const _35b71bd7 = () => interopDefault(import('~/modules/lms/pages/lmsCourses/_id/lesson.vue' /* webpackChunkName: "" */))
const _822e1d68 = () => interopDefault(import('~/modules/lms/pages/lmsCourses/lmsLessons/_lmsLessons.vue' /* webpackChunkName: "" */))
const _d009e0cc = () => interopDefault(import('~/modules/lms/pages/lmsCourses/lmsSections/_lmsSections.vue' /* webpackChunkName: "" */))
const _7dd2ef8c = () => interopDefault(import('~/modules/lms/pages/lmsDirectives/index.vue' /* webpackChunkName: "" */))
const _ed226c78 = () => interopDefault(import('~/modules/lms/pages/lmsDirectives/create.vue' /* webpackChunkName: "" */))
const _6b3d5122 = () => interopDefault(import('~/modules/lms/pages/lmsDirectives/_id.vue' /* webpackChunkName: "" */))
const _a4e3a936 = () => interopDefault(import('~/modules/lms/pages/lmsUsers.vue' /* webpackChunkName: "" */))
const _69a3d228 = () => interopDefault(import('~/modules/lms/pages/lmsUsers/index.vue' /* webpackChunkName: "" */))
const _2cd03e90 = () => interopDefault(import('~/modules/lms/pages/lmsUsers/_id.vue' /* webpackChunkName: "" */))
const _107bbfb8 = () => interopDefault(import('~/modules/lms/pages/lmsBlocks/_id.vue' /* webpackChunkName: "" */))
const _46f6f8fc = () => interopDefault(import('~/modules/lms/pages/lmsBlocks/create.vue' /* webpackChunkName: "" */))
const _e7db6806 = () => interopDefault(import('~/modules/lms/pages/lmsExpiringlinks/index.vue' /* webpackChunkName: "" */))
const _4a65acb6 = () => interopDefault(import('~/modules/lms/pages/lmsExpiringlinks/_id.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _07d4f574,
    name: "account"
  }, {
    path: "/Avatar",
    component: _e2477afc,
    name: "Avatar"
  }, {
    path: "/forgotPassword",
    component: _8803ef32,
    name: "forgotPassword"
  }, {
    path: "/login",
    component: _b9774720,
    name: "login"
  }, {
    path: "/old",
    component: _99e50824,
    name: "old"
  }, {
    path: "/resetPassword",
    component: _1a345ade,
    name: "resetPassword"
  }, {
    path: "/signup",
    component: _11a11ea1,
    name: "signup"
  }, {
    path: "/families/access",
    component: _e6ede84a,
    name: "families-access"
  }, {
    path: "/families/edit",
    component: _a725f804,
    name: "families-edit"
  }, {
    path: "/families/old",
    component: _02c78163,
    name: "families-old"
  }, {
    path: "/",
    component: _4cc68d4e,
    name: "index"
  }, {
    path: "/courses/:slug",
    component: _a0644306,
    name: "courses-slug"
  }, {
    path: "/families/:id",
    component: _1ef2d979,
    name: "families-id"
  }, {
    path: "/courses/:slug?/overview",
    component: _1b98a29e,
    name: "courses-slug-overview"
  }, {
    path: "/families/:id?/reflection",
    component: _e0c828d8,
    name: "families-id-reflection"
  }, {
    path: "/families/:id?/training",
    component: _92570dfa,
    name: "families-id-training"
  }, {
    path: "/courses/:slug?/lessons/lessonId_temp",
    component: _332461f7,
    name: "courses-slug-lessons-lessonId_temp"
  }, {
    path: "/courses/:slug?/lessons/:lessonId?",
    component: _0bd22629,
    children: [{
      path: "",
      component: _5460fa28,
      name: "courses-slug-lessons-lessonId"
    }]
  }, {
    path: "/lms",
    component: _49646856,
    name: "lmsindex"
  }, {
    path: "/lms/lmsCourses",
    component: _49646856,
    name: "courses",
    children: [{
      path: "/",
      component: _15e55e98,
      name: "CourseIndex"
    }, {
      path: "create",
      component: _630a81b4,
      name: "createCourse"
    }, {
      path: ":id",
      component: _37ea2700,
      name: "course",
      children: [{
        path: "chapter",
        component: _7a4c293e,
        name: "chapter"
      }, {
        path: ":section/lesson",
        component: _35b71bd7,
        name: "lesson"
      }]
    }, {
      path: "lmsLessons/:id",
      component: _822e1d68,
      name: "lessons"
    }, {
      path: "lmsSections/:id",
      component: _d009e0cc,
      name: "sections"
    }]
  }, {
    path: "/lms/lmsDirectives",
    component: _7dd2ef8c,
    name: "directives",
    children: [{
      path: "create",
      component: _ed226c78,
      name: "createDirective"
    }, {
      path: ":id",
      component: _6b3d5122,
      name: "directive"
    }]
  }, {
    path: "/lms/lmsUsers",
    component: _a4e3a936,
    name: "users",
    children: [{
      path: "/",
      component: _69a3d228,
      name: "users"
    }, {
      path: ":id",
      component: _2cd03e90,
      name: "lmsUser"
    }]
  }, {
    path: "/lms/lmsBlocks/:id",
    component: _107bbfb8,
    name: "block"
  }, {
    path: "/lms/lmsBlocksCreate",
    component: _46f6f8fc,
    name: "createBlockPage"
  }, {
    path: "/lms/lmsExpiringlinks",
    component: _e7db6806,
    name: "lmsStories"
  }, {
    path: "/lms/lmsExpiringlinks/:id",
    component: _4a65acb6,
    name: "story"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
