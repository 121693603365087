
export default {
  props: {
    sections: {
      type: Array,
      required: true
    },
    course: {
      type: Object,
      required: false,
      default: null
    },
    navigable: {
      type: Boolean,
      required: false,
      default: false
    },
    activeSection: {
      type: Object,
      required: false,
      default: null
    },
    activeLesson: {
      type: Object,
      required: false,
      default: null
    },
    isSidebar: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    isInformationalCourse() {
      console.log({ course: this.course, isInformationalCourse: this.course.course_design !== "course" })
      return this.course.course_design !== "course"
    }
  },
  methods: {
    getLesson(lessonId) {
      return this.$store.getters["lessons/find"](lessonId)
    }
  }
}
