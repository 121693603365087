import Vue from "vue"
export default {
  active (state, active) {
    state.active = active
  },
  FLUSH_ACTIVE (state) {
    state.active = false
  },
  flush (state) {
    state.all = {}
    const resource = {
      page: 1,
      ids: {}
    }
    const index = state.reference[resource.page]
    if (index === -1) {
      Vue.set(state.reference, resource.page, resource.ids)
    } else {
      const temp = { ...state.reference[resource.page], ...resource }
      Vue.set(state.reference, resource.page, temp)
    }
  },
  // add(state, resource) {
  //   Vue.set(state.all, resource.id, resource);
  // },
  add: (state, item) => {
    Vue.set(state.all, item.id, item)
    // if (state.allIds.includes(item.id)) return;
    // state.allIds.push(item.id);
  },

  MERGE (state, resource) {
    for (const r in resource) {
      // Get existing object from state, or create an empty object based on a stub
      const oldObj = state.all[r] || Object.assign({}, state.stub)

      // Merge the new data into the old object.
      const newObj = Object.assign({}, oldObj, resource[r])

      // Set new object in state.
      Vue.set(state.all, r, newObj)
      // Vue.set(state.all, resource.id, resource[r]);
      // const index = state.all[r.id];
      // if (index === -1) {

      // } else {
      //   let temp = { ...state.all[resource.id], ...resource };
      //   Vue.set(state.all, resource.id, temp);
    }

    // const index = state.all[resource.id];
    // if (index === -1) {
    //   Vue.set(state.all, resource.id, resource);
    // } else {
    //   let temp = { ...state.all[resource.id], ...resource };
    //   Vue.set(state.all, resource.id, temp);
    // }
  },
  MERGEHEADERS (state, resource) {
    const index = state.headers
    if (index === -1) {
      Vue.set(state.headers, resource.field, resource)
    } else {
      const temp = { ...state.headers[resource.field], ...resource }
      Vue.set(state.headers, resource.field, temp)
    }
  },

  MERGENOTIFICATIONS (state, resource) {
    const index = state.notifications
    if (index === -1) {
      Vue.set(state.notifications, resource.id, resource)
    } else {
      const temp = { ...state.notifications[resource.id], ...resource }
      Vue.set(state.notifications, resource.id, temp)
    }
  },
  FLUSHNOTIFICATIONS (state) {
    state.notifications = {}
  },
  MERGEFILTERS (state, resource) {
    // console.log(resource);
    state.filter = resource

    // Object.assign(state.filter, payload);
    // Vue.set(state, state.filter, resource);
    // for (let key in resource) {
    //   console.log(resource[key]);
    //   //
    //   let value = resource[key];
    //   let temp = { ...state.filter[key], ...value };
    //   Vue.set(state.filter, key, temp);
    // }

    // if (index === -1) {
    //   Vue.set(state.filter, resource.field, resource);
    // } else {
    //   let temp = { ...state.filter[resource.field], ...resource };
    //   Vue.set(state.filter, resource.field, temp);
    // }
  },

  SET_REFERENCE (state, resource) {
    const index = state.reference[resource.page]
    if (index === -1) {
      Vue.set(state.reference, resource.page, resource.ids)
    } else {
      const temp = { ...state.reference[resource.page], ...resource }
      Vue.set(state.reference, resource.page, temp)
    }
  },
  update (state, resource) {
    const index = state.all[resource.id]
    if (index === -1) {
      Vue.set(state.all, resource.id, resource)
    } else {
      const temp = { ...state.all[resource.id], ...resource }
      Vue.set(state.all, resource.id, temp)
    }
  },
  nullify (state, id) {
    const index = state.all[id]
    if (index !== -1) {
      Vue.set(state.all, id, null)
    }
  },
  paginate (state, data) {
    state.pagination = data
  },
  totalItems (state, data) {
    state.total = data
  },

  SET_PAGE (state, data) {
    state.page = data
  },
  SET_SEARCH (state, data) {
    state.search = data
  },
  SET_FILTER (state, data) {
    state.filter = data
  },
  RESET_FILTER (state) {
    Object.assign(state.filter, state.default_filter)
  },
  REMOVE (state, index) {
    Vue.delete(state.all, index)
  },
  updateBound (state, { key, value }) {
    Vue.set(state.bound, key, value)
  },
  replaceFilter (state, data) {
    Vue.set(state, "filter", data)
  },
  updateFilter (state, { key, value }) {
    const index = state.filter[key]
    if (index === -1) {
      Vue.set(state.filter, key, value)
    } else {
      const temp = { ...state.filter[key], ...value }
      Vue.set(state.filter, key, temp)
    }
  },
  SET_PRINT_PATH (state, path) {
    state.print_path = path
  }
}
