import moment from "moment"
import config from "./filterMenu.js"

export function filterRelationLabels () {
  return [
    {
      field: "courses",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Attach Courses",
      type: "modelAutocomplete",
      value: "courses",
      used: true,
      model: "courses",
      itemValue: "id",
      itemText: "title",
      multiple: true,
      mappedField: "course.id",
      mapped: "courses",
      order: 1,
      column: 1
    },
    {
      field: "lmsCourses",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Attach Courses",
      type: "modelAutocomplete",
      value: "courses",
      used: true,
      model: "lmsCourses",
      itemValue: "id",
      itemText: "title",
      multiple: true,
      mappedField: "course.id",
      mapped: "courses",
      order: 1,
      column: 1
    },
    {
      field: "certificates",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Certificates",
      type: "modelAutocomplete",
      value: "certificates",
      used: true,
      model: "certificates",
      itemValue: "id",
      itemText: "title",
      multiple: true,
      mappedField: "certificate.id",
      mapped: "certificates",
      order: 1,
      column: 1
    },
    {
      field: "lmsCertificates",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Certificates",
      type: "modelAutocomplete",
      value: "certificates",
      used: true,
      model: "lmsCertificates",
      itemValue: "id",
      itemText: "title",
      multiple: true,
      mappedField: "certificate.id",
      mapped: "certificates",
      order: 1,
      column: 1
    },
    {
      field: "sections",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Sections",
      type: "modelAutocomplete",
      value: "sections",
      used: true,
      model: "sections",
      itemValue: "id",
      itemText: "title",
      multiple: true,
      mappedField: "section.id",
      mapped: "sections",
      order: 1,
      column: 1
    },
    {
      field: "lessons",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Lessons",
      type: "modelAutocomplete",
      value: "lessons",
      used: true,
      model: "lessons",
      itemValue: "id",
      itemText: "title",
      multiple: true,
      mappedField: "lesson.id",
      mapped: "lessons",
      order: 1,
      column: 1
    },
    {
      field: "files",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Files",
      type: "modelAutocomplete",
      value: "files",
      used: true,
      model: "files",
      itemValue: "id",
      itemText: "title",
      multiple: true,
      mappedField: "file.id",
      mapped: "files",
      order: 1,
      column: 1
    },
    {
      field: "users",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Users",
      type: "modelAutocomplete",
      value: "users",
      used: true,
      model: "users",
      itemValue: "id",
      itemText: "title",
      multiple: true,
      mappedField: "user.id",
      mapped: "users",
      order: 1,
      column: 1
    },
    {
      field: "lmsUsers",
      icon: "mdi-account-badge-horizontal-outline",
      display: true,
      label: "Users",
      type: "modelAutocomplete",
      value: "users",
      used: true,
      model: "lmsUsers",
      itemValue: "id",
      itemText: "title",
      multiple: true,
      mappedField: "user.id",
      mapped: "users",
      order: 1,
      column: 1
    }
  ]
}

export function resolveFilters (item, value) {
  let result = ""
  switch (item) {
    case "without_attr": {
      result = "missing" + value.join(",")
      break
    }
    case "with_attr": {
      result = "Any" + value.join(",")
      break
    }

    case "without_rel": {
      result = "missing" + value.join(",")
      break
    }

    case "with_rel": {
      const display = value.filter(obj => obj.text)
      result = display.join(",")
      break
    }

    case "attr_before_date": {
      result = "Before " + moment(value[0], "YYYY-MM-DD").format("MM/DD/YY")
      break
    }

    case "attr_between_date": {
      result =
          moment(value[0], "YYYY-MM-DD").format("MM/DD/YY") +
          " - " +
          moment(value[1], "YYYY-MM-DD").format("MM/DD/YY")
      console.log(value[0])
      break
    }

    case "attr_between_days_next": {
      result =
          moment()
            .add(value[0], "days")
            .format("MM/DD/YY") +
          " - " +
          moment()
            .add(value[1], "days")
            .format("MM/DD/YY")
      break
    }

    case "attr_between_days_past": {
      result =
          moment()
            .subtract(value[0], "days")
            .format("MM/DD/YY") +
          " - " +
          moment()
            .subtract(value[1], "days")
            .format("MM/DD/YY")
      break
    }

    case "attr_within_days_next": {
      console.log("value[0]")
      console.log(value[0])
      result =
          moment().format("MM/DD/YY") +
          " - " +
          moment()
            .add(value[0], "days")
            .format("MM/DD/YY")
      break
    }

    case "attr_this_month": {
      const today = moment().format("MMM, YYYY")
      result = "in " + today
      break
    }

    default:
      result = value
  }

  return result
}

export function formatFilter (filter) {
  const list = []
  _.forEach(filter, function (item, type) {
    if (type === "exact") {
      Object.entries(item).forEach(([key, value]) => {
        const entryValue = []
        Object.entries(value).forEach(([k, v]) => {
          entryValue.push(v)
        })
        const obj = {
          value: entryValue.join(","),
          type,
          key
        }
        list.push(obj)
      })
    } else {
      Object.entries(filter).forEach(([key, value]) => {
        const obj = {
          value: resolveFilterFormat(type, Object.values(value)),
          type,
          key
        }
        list.push(obj)
      })
    }
  })

  return list
}

// import this into vuex getters
export function resolveRelationFilters (relations) {
  const list = []

  _.forEach(relations, function (filter, name) {
    // filter is an obj {with_rel: {id:6}}
    // name = author

    const temp = formatFilter(filter)

    // probably should merge this
    list.push(temp)
  })
}

export function resolveSavedFilters (type) {
  // TODO: move config here so that we can access the auth.user.

  const filters = config[type]
  return filters
}
