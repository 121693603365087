
export default {
  props: {
    lessons: {
      type: Array,
      required: true
    },
    course: {
      type: Object,
      required: false,
      default: null
    },
    section: {
      type: Object,
      required: true,
      default: null
    },
    navigable: {
      type: Boolean,
      required: false,
      default: false
    },
    activeLesson: {
      type: Object,
      required: false,
      default: null
    },
    isSidebar: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    displayedLessons () {
      return Object.values(this.$store.state.lessons.all)
        .filter(lesson => this.lessons.includes(lesson.id))
        .sort((a, b) => a.position - b.position)
    },
    user () {
      return this.$store.getters["users/active"]
    }
    // registered () {
    //   return this.user.courses && this.user.courses.filter(c => c.id === this.active.id).length
    // }

  },
  methods: {
    navigate (id) {
      if (this.navigable) {
        return this.$router.push(`/courses/${this.course.slug}/lessons/${id}`)
      }
    },
    visited (id) {
      let visited = false
      if (this.user.lessons.some(e => e.id === id)) {
        visited = "started"
        const userLesson = this.user.lessons.find(lesson => lesson.id === id)
        if (userLesson.completed) {
          visited = "completed"
        }
      }
      return visited
    }
  }
}
