import { render, staticRenderFns } from "./student_alt.vue?vue&type=template&id=0485de20&scoped=true&"
import script from "./student_alt.vue?vue&type=script&lang=js&"
export * from "./student_alt.vue?vue&type=script&lang=js&"
import style0 from "./student_alt.vue?vue&type=style&index=0&id=0485de20&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0485de20",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Search: require('/opt/buildhome/repo/components/Search.vue').default,Banner: require('/opt/buildhome/repo/components/Banner.vue').default})
