import { normalize } from "normalizr"

export default {
  async set_active ({ commit, state, getters, dispatch, rootState }, id) {
    await commit("active", id)
  },
  /**
     *
     * MERGEHEADERS builds out the schema for each module found in the store.
     * these headers are kept in the db and a built out a JSON structure in the laravel migrations.
     */

  MERGEHEADERS ({ commit, state }, payload) {
    const schema = Object.values(payload)

    schema.forEach((item) => {
      commit("MERGEHEADERS", item)
    })
  },

  /**
     *
     * MERGEHEADERS builds out the schema for each module found in the store.
     * these headers are kept in the db and a built out a JSON structure in the laravel migrations.
     */

  UpdateBound ({ commit, state }, payload) {
    const schema = Object.values(payload)

    schema.forEach((item) => {
      const p = {}
      if (item.type === "checkbox") {
        p.key = item.field
        p.value = 0
      } else {
        p.key = item.field
        p.value = ""
      }
      commit("updateBound", p)
    })
  },
  /**
     *
     * MERGENOTIFICATIONS A special action for user notifications.  This could probably be refactored.
     */

  MERGENOTIFICATIONS ({ commit, state }, payload) {
    commit("MERGENOTIFICATIONS", payload)
    // let schema = Object.values(payload);
    // schema.forEach(item => {
    //   commit("MERGENOTIFICATIONS", item);
    // });
  },

  /**
     *
     * MERGEHEADERS builds out the filters for each module found in the store.
     * these filters are user generated and kept in the db.
     */

  MERGEFILTERS ({ commit, state }, payload) {
    const newSchema = {}
    for (const key in payload) {
      const value = payload[key]

      let newValue
      if (value === null) {
        newValue = ""
      } else {
        newValue = value
      }

      newSchema[key] = newValue
      // commit("updateFilter", { key, newValue });
    }

    commit("MERGEFILTERS", newSchema)
  },

  /**
     *
     * set_reference is a pagination feature in site of the store. When a index request is made, the results are merged in.
     * the reference allows lists to show only the filtered or paginated items instead of the entire list of a resource.
     */

  set_reference ({ commit, state }, obj) {
    commit("SET_REFERENCE", obj)
  },

  /**
     * set_total is used to track the total records in a request, used for pagination.
     *
     */

  set_total ({ commit, state }, total) {
    commit("totalItems", total)
  },

  /**
     * success and error tracking "toast". this function sets the message content and style
     *
     */

  set_snack ({ commit, state }, obj) {
    if (obj.hasOwnProperty("error")) {
      let message =
                "Error connecting to server!" +
                " Your internet connection may be having issues."
      if (obj.error !== undefined) {
        message = `${obj.error.statusText}: ${obj.error.data.message}` +
                    (obj.error.data.exception ? ` ${obj.error.data.exception}` : "") +
                    (obj.error.data.file ? ` ${obj.error.data.file}` : "") +
                    (obj.error.data.line ? ` ${obj.error.data.line}` : "")
      }
      const snackSettings = {
        color: "error",
        top: true,
        timeout: 16000,
        right: false
      }
      commit("system/set_snackSettings", snackSettings, { root: true })
      commit("system/SET_SNACK", message, { root: true })
    }
  },

  /**
     *
     * An index list request from the API.  Normalizes data and merges it into the correct module
     */

  async search ({ commit, state, getters, dispatch }, params) {
    const results = await this.$repositories[state.type].index(params)

    const { entities } = normalize(results.data, [
      this.$relationships[state.type]
    ])
    commit("MERGE", entities[state.type])
    if (state.relations.length > 0) {
      state.relations.forEach((relation) => {
        // this will merge relationships via their own module
        dispatch(`${relation.value}/merge`, entities[relation.value], {
          root: true
        })
      })
    }
  },

  /**
     *
     * An index list request from the API.  Normalizes data and merges it into the correct module
     */

  async list ({ commit, state, getters, dispatch }) {
    const params = getters.params
    if (params.hasOwnProperty("print")) {
      delete params.print
    }
    if (params.hasOwnProperty("export")) {
      delete params.export
    }
    // if params has print/export, remove it
    const results = await this.$repositories[state.type].index(params)
    const ids = results.data.map(item => item.id)
    const page = state.page
    dispatch("set_snack", results)

    const { entities } = normalize(results.data, [
      this.$relationships[state.type]
    ])
    commit("MERGE", entities[state.type])
    if (state.relations.length > 0) {
      state.relations.forEach((relation) => {
        // this will merge relationships via their own module
        dispatch(`${relation.value}/merge`, entities[relation.value], {
          root: true
        })
      })
    }
    const obj = {
      page,
      ids
    }
    /* Adds a encyclopedia that maps pages to index */
    dispatch("set_reference", obj)
    dispatch("set_total", results.meta.total)
  },

  /**
     *
     * subList should return a list of subresources.
     * Example:  members/1/tags should return a list of tags
     * ? I'm not sure this is needed with the switch to normalizr.js
     */

  async subList ({ commit, state, getters, dispatch }, payload) {
    const type = payload.type
    const id = payload.id
    const child = payload.child
    const results = await this.$repositories[type].subIndex(id)

    dispatch(`${child}/subListMerge`, results.data, {
      root: true
    })

    dispatch("set_snack", results)

    const ids = results.data.map(item => item.id)
    const page = 1
    const obj = {
      page,
      ids
    }
    /* Adds a encyclopedia that maps pages to index */
    dispatch(`${child}/set_reference`, obj, { root: true })
    dispatch(`${child}/set_total`, results.data.length, { root: true })
  },

  /**
     *
     * ? is this still needed?
     * subListMerge is triggered by the child of the sublist method (above).
     * It is a seperate action because GlobalActions is called by each store.
     */

  subListMerge ({ commit, state, getters, dispatch }, payload) {
    const { entities } = normalize(payload, [this.$relationships[state.type]])
    commit("MERGE", entities[state.type])
    if (state.relations.length > 0) {
      state.relations.forEach((relation) => {
        // this will merge relationships via their own module
        dispatch(`${relation.value}/merge`, entities[relation.value], {
          root: true
        })
      })
    }
  },

  merge ({ commit, state, dispatch }, payload) {
    commit("MERGE", payload)
  },

  /**
     *
     * Create saves a model.  It should return a single model of state.type with relationships
     *
     */
  async create ({ commit, state, getters, dispatch, rootState }, payload) {
    const results = await this.$repositories[state.type].create(payload)
    if (results.hasOwnProperty("error")) {
      dispatch("set_snack", results)
      return false
    } else {
      let stateType = state.type
      if (stateType === "emailsend") {
        stateType = "emailtemplateshistory"
      }
      const { entities } = normalize(
        [results.data],
        [this.$relationships[stateType]]
      )
      commit("MERGE", entities[stateType])
      if (state.relations.length > 0) {
        state.relations.forEach((relation) => {
          // this will merge relationships via their own module
          dispatch(`${relation.value}/merge`, entities[relation.value], {
            root: true
          })
        })
      }

      if (state.type !== "emailtemplates" && state.type !== "filters") {
        dispatch("set_active", results.data.id)
        dispatch("list", results)
      }

      // really need to dispatch list here?

      return results
    }
  },

  /**
     * subCreate creates a child resource of a parent module. It should return an instance of the parent
     * example:  members/1/tags should save a tag and return the member
     * @param payload is an obj that should include:
     * @param type This is the name of api endpoint expressed in camelcase e.g memberRenewals
     * @param id this is the id of the parent tag/id/notes
     * @param resource this is the contents that should be saved in the api call
     */
  async subCreate ({ commit, state, getters, dispatch, rootState }, payload) {
    const type = payload.type
    const id = payload.id
    const resource = payload.resource

    const results = await this.$repositories[type].subCreate(id, resource)
    if (results.hasOwnProperty("error")) {
      dispatch("set_snack", results)
      return false
    } else {
      const { entities } = normalize(
        [results.data],
        [this.$relationships[state.type]]
      )
      commit("MERGE", entities[state.type])
      if (state.relations.length > 0) {
        state.relations.forEach((relation) => {
          // this will merge relationships via their own module
          dispatch(`${relation.value}/merge`, entities[relation.value], {
            root: true
          })
        })
      }
      return results
    }
  },
  /**
     * createFile uploads a file on a nested api. It should return an instance of the parent
     * example:  tags/1/documents should save a document and return the tag
     */

  async createFile ({ commit, state, getters, dispatch, rootState }, payload) {
    const type = payload.type
    const resource = payload.formData

    const results = await this.$repositories[type].create(resource)
    if (results.hasOwnProperty("error")) {
      dispatch("set_snack", results)
      return false
    } else {
      const { entities } = normalize(
        [results.data],
        [this.$relationships[state.type]]
      )
      commit("MERGE", entities[state.type])
      if (state.relations.length > 0) {
        state.relations.forEach((relation) => {
          // this will merge relationships via their own module
          dispatch(`${relation.value}/merge`, entities[relation.value], {
            root: true
          })
        })
      }
      return true
    }
  },

  async createFileUpload (
    { commit, state, getters, dispatch, rootState },
    payload
  ) {
    const type = payload.type
    const resource = payload.formData

    const results = await this.$repositories[type].createFileUpload(resource)
    if (results.hasOwnProperty("error")) {
      dispatch("set_snack", results)
      return false
    } else {
      const { entities } = normalize(
        [results.data],
        [this.$relationships[state.type]]
      )
      commit("MERGE", entities[state.type])
      if (state.relations.length > 0) {
        state.relations.forEach((relation) => {
          // this will merge relationships via their own module
          dispatch(`${relation.value}/merge`, entities[relation.value], {
            root: true
          })
        })
      }
      return true
    }
  },

  /**
     * subCreateFile uploads a file on a nested api. It should return an instance of the parent
     * example:  tags/1/documents should save a document and return the tag
     */
  async subCreateFile (
    { commit, state, getters, dispatch, rootState },
    payload
  ) {
    const type = payload.type
    const id = payload.id
    const resource = payload.formData

    const results = await this.$repositories[type].subCreateFile(id, resource)
    if (results.hasOwnProperty("error")) {
      dispatch("set_snack", results)
      return false
    } else {
      const { entities } = normalize(
        [results.data],
        [this.$relationships[state.type]]
      )
      commit("MERGE", entities[state.type])
      if (state.relations.length > 0) {
        state.relations.forEach((relation) => {
          // this will merge relationships via their own module
          dispatch(`${relation.value}/merge`, entities[relation.value], {
            root: true
          })
        })
      }
      return true
    }
  },

  /**
     *
     * Show should return a single model instance.
     */
  async show ({ commit, state, getters, dispatch, rootState }, id) {
    const results = await this.$repositories[state.type].show(id)
    dispatch("set_snack", results)
    const { entities } = normalize(
      [results.data],
      [this.$relationships[state.type]]
    )

    // debugger;
    commit("MERGE", entities[state.type])
    // debugger;
    if (state.relations.length > 0) {
      state.relations.forEach((relation) => {
        // this will merge relationships via their own module

        dispatch(`${relation.value}/merge`, entities[relation.value], {
          root: true
        })
      })
    }
    commit("active", results.data.id)

    return results
  },

  async execute ({ commit, state, getters, dispatch, rootState }, id) {
    const results = await this.$repositories[state.type].execute(id)
    dispatch("set_snack", results)
    const { entities } = normalize(
      [results.data],
      [this.$relationships[state.type]]
    )

    // debugger;
    commit("MERGE", entities[state.type])
    // debugger;
    if (state.relations.length > 0) {
      state.relations.forEach((relation) => {
        // this will merge relationships via their own module

        dispatch(`${relation.value}/merge`, entities[relation.value], {
          root: true
        })
      })
    }
  },

  authShow ({ commit, state, getters, dispatch, rootState }, user) {
    // dispatch("set_snack", results);
    const { entities } = normalize(
      [user.data],
      [this.$relationships[state.type]]
    )

    // debugger;
    commit("MERGE", entities[state.type])
    // debugger;
    if (state.relations.length > 0) {
      state.relations.forEach((relation) => {
        // this will merge relationships via their own module

        dispatch(`${relation.value}/merge`, entities[relation.value], {
          root: true
        })
      })
    }
  },

  /**
     *
     * Update should modify a single model and return it.
     */
  async update ({ commit, state, getters, dispatch }, { id, payload }) {
    const results = await this.$repositories[state.type].update({
      id,
      payload
    })

    if (results.hasOwnProperty("error")) {
      await dispatch("set_snack", results)
      return results
    } else {
      const { entities } = normalize(
        [results.data],
        [this.$relationships[state.type]]
      )
      commit("MERGE", entities[state.type])
      if (state.relations.length > 0) {
        state.relations.forEach((relation) => {
          // this will merge relationships via their own module
          dispatch(`${relation.value}/merge`, entities[relation.value], {
            root: true
          })
        })
      }
      return true
    }
  },
  async subUpdate ({ commit, state, getters, dispatch, rootState }, payload) {
    const type = payload.type
    const id = payload.id
    const childId = payload.childId
    const resource = payload.resource

    const results = await this.$repositories[type].subUpdate(
      id,
      childId,
      resource
    )
    if (results.hasOwnProperty("error")) {
      dispatch("set_snack", results)
      return false
    } else {
      const { entities } = normalize(
        [results.data],
        [this.$relationships[state.type]]
      )
      commit("MERGE", entities[state.type])
      if (state.relations.length > 0) {
        state.relations.forEach((relation) => {
          // this will merge relationships via their own module
          dispatch(`${relation.value}/merge`, entities[relation.value], {
            root: true
          })
        })
      }
      return true
    }
  },
  async relationUpdate (
    { commit, state, getters, dispatch, rootState },
    { id, payload }
  ) {
    const results = await this.$repositories[state.type].update({
      id,
      payload
    })
    if (results.hasOwnProperty("error")) {
      dispatch("set_snack", results)
      return false
    } else {
      const { entities } = normalize(
        [results.data],
        [this.$relationships[state.type]]
      )
      commit("MERGE", entities[state.type])

      return true
    }
  },

  /**
     *
     * Detach removes a relationship.
     * members/1/tags/1 should remove the relationship of tag 1 and member 1
     * this should return a single parent reource (member in my example)
     */
  async detach (
    { commit, state, getters, dispatch },
    { parentId, childId, path, apiPath }
  ) {
    const results = await this.$repositories[apiPath].subDelete(
      parentId,
      childId
    )
    if (results.hasOwnProperty("error")) {
      dispatch("set_snack", results)
    } else {
      // returns parent
      const { entities } = normalize(
        [results.data],
        [this.$relationships[state.type]]
      )
      commit("MERGE", entities[state.type])
      if (state.relations.length > 0) {
        state.relations.forEach((relation) => {
          // this will merge relationships via their own module
          dispatch(`${relation.value}/merge`, entities[relation.value], {
            root: true
          })
        })
      }
    }
  },
  resetFilter ({ commit, state, getters, dispatch }, filter) {
    commit("RESET_FILTER")
  },

  filter ({ commit, state, getters, dispatch }, filter) {
    commit("updateFilter", filter)
    dispatch("list")
  },
  async print ({ commit, state, getters, dispatch }, printedColumns) {
    const x = getters.params

    x.print = true
    x.export = false
    x.printedColumns = printedColumns
    const results = await this.$repositories[state.type].print(x)
    if (results.hasOwnProperty("error")) {
      dispatch("set_snack", results)
    } else {
      commit("SET_PRINT_PATH", results.data.path)
    }
  },
  async export ({ commit, state, getters }, printedColumns) {
    const x = getters.params
    x.export = true
    x.print = false
    x.printedColumns = printedColumns

    const results = await this.$repositories[state.type].print(x)
    if (results.hasOwnProperty("error")) {
      dispatch("set_snack", results)
    } else {
      commit("SET_PRINT_PATH", results.data.path)
    }
  },
  async subListPrint ({ commit, state, getters, dispatch }, payload) {
    const type = payload.type
    const id = payload.id

    const printedColumns = payload.printedColumns

    const x = {}
    x.print = true
    x.export = false
    x.printedColumns = printedColumns

    const results = await this.$repositories[type].subPrint(id, x)
    if (results.hasOwnProperty("error")) {
      dispatch("set_snack", results)
      return false
    } else {
      commit("SET_PRINT_PATH", results.data.path)
    }
  },
  async subListExport ({ commit, state, getters, dispatch }, payload) {
    const type = payload.type
    const id = payload.id

    const printedColumns = payload.printedColumns

    const x = {}
    x.export = true
    x.print = false
    x.printedColumns = printedColumns

    const results = await this.$repositories[type].subExport(id, x)

    if (results.hasOwnProperty("error")) {
      dispatch("set_snack", results)
    } else {
      commit("SET_PRINT_PATH", results.data.path)
    }
  },
  async showPrint ({ commit, state, getters, dispatch }, payload) {
    const id = payload.id
    const printedColumns = payload.printedColumns

    const x = {}
    x.print = true
    x.export = false
    x.printedColumns = printedColumns

    const results = await this.$repositories[state.type].showPrint(id, x)
    if (results.hasOwnProperty("error")) {
      dispatch("set_snack", results)
      return false
    } else {
      commit("SET_PRINT_PATH", results.data.path)
    }
  },

  //   async print({ commit, state, getters }, format, printedColumns = null) {
  //     let x = getters.params;
  //     x["print"] = true;
  //     x["format"] = format;
  //     x["printedColumns"] = printedColumns;

  //     const results = await this.$repositories[state.type].print(x);

  //     commit("SET_PRINT_PATH", results.data.path);
  //   }

  /**
     * PORTAL
     */

  async portalList ({ commit, state, getters, dispatch, rootState }) {
    const params = getters.params
    // params.tenant = rootState.system.tenant;
    const results = await this.$portalRepositories[state.type].index(params)
    const ids = results.data.map(item => item.id)
    const page = state.page
    dispatch("set_snack", results)

    const { entities } = normalize(results.data, [
      this.$relationships[state.type]
    ])

    commit("MERGE", entities[state.type])
    if (state.relations.length > 0) {
      state.relations.forEach((relation) => {
        // this will merge relationships via their own module
        dispatch(`${relation.value}/merge`, entities[relation.value], {
          root: true
        })
      })
    }
    const obj = {
      page,
      ids
    }

    /* Adds a encyclopedia that maps pages to index */
    dispatch("set_reference", obj)
    dispatch("set_total", results.meta.total)
  },

  async portalShow ({ commit, state, getters, dispatch, rootState }, id) {
    const params = {}
    // params.tenant = rootState.system.tenant;
    const results = await this.$repositories[state.type].show(id, params)
    dispatch("set_snack", results)
    const { entities } = normalize(
      [results.data],
      [this.$relationships[state.type]]
    )

    // debugger;
    commit("MERGE", entities[state.type])
    // debugger;
    if (state.relations.length > 0) {
      state.relations.forEach((relation) => {
        // this will merge relationships via their own module

        dispatch(`${relation.value}/merge`, entities[relation.value], {
          root: true
        })
      })
    }
    commit("active", results.data.id)
  },
  async portalCreate ({ commit, state, getters, dispatch, rootState }, payload) {
    const results = await this.$repositories[state.type].create(payload)
    if (results.hasOwnProperty("error")) {
      dispatch("set_snack", results)
      return false
    } else {
      const stateType = state.type

      const { entities } = normalize(
        [results.data],
        [this.$relationships[stateType]]
      )
      commit("MERGE", entities[stateType])
      if (state.relations.length > 0) {
        state.relations.forEach((relation) => {
          // this will merge relationships via their own module
          dispatch(`${relation.value}/merge`, entities[relation.value], {
            root: true
          })
        })
      }
      commit("active", results.data.id)
      return results
    }
  }
}
