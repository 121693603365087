// State ======================
import globalActions from "../api/GlobalActions.js"
import globalMutations from "../api/GlobalMutations.js"
import globalGetters from "../api/GlobalGetters.js"

export const state = () => ({
  type: "system",
  relations: [],
  all: {},
  fear: 1,
  anxiety: 1,
  showAnxiety: true,
  viewGuide: null,
  is_layout_fluid: false,
  reference: {
    /* for showing paginated resources ... needs a bit more work. */
  },
  active: 1,
  page: 1,
  pageCount: 0,
  pastView: [],
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: "name",
    page: 1,
    sortDesc: []
  },
  total: 0,
  print: false,
  print_path: "",
  export: false,
  search: null,
  // this will need to by dynamic based on user config valuese
  bound: {},
  stub: {},
  filter: {},
  // members are called contractors on the server
  include: "",
  headers: {},
  notifications: {}
})

// Mutations ==================

export const mutations = {
  ...globalMutations,
  SET_SNACK (state, snack) {
    state.snack = snack
  },

  set_snackSettings: (state, snackSettings) => {
    state.snackSettings = snackSettings
  },

  set_validateFields: (state, validateFields) => {
    state.validateFields = validateFields
  },

  set_showFormErrors: (state, showFormErrors) => {
    state.showFormErrors = showFormErrors
  },

  SET_FEAR (state, fear) {
    state.fear = fear
  },
  SET_ANXIETY (state, anxiety) {
    state.anxiety = anxiety
  },
  SET_DISPLAYANXIETY (state, anxiety) {
    state.showAnxiety = anxiety
  },
  SET_HISTORY (state, payload) {
    state.pastView.unshift(payload)
  },
  SET_GUIDE (state, payload) {
    state.viewGuide = payload
  },
  NAVIGATE_ACTIVE_DIALOG_BACK (state) {
    // state.pastView.shift();
    state.pastView.splice(0, 2)
  },
  SET_LAYOUT_FLUID (state) {
    state.is_layout_fluid = true
  },
  UNSET_LAYOUT_FLUID (state) {
    state.is_layout_fluid = false
  }
}

// Actions ====================

export const actions = {
  ...globalActions,
  set_history ({ commit, state }, payload) {
    commit("SET_HISTORY", payload)
  },
  async navBack ({ commit, state, getters, dispatch, rootState }) {
    const newActive = state.pastView[1]
    await dispatch("presentations/set_active", newActive, {
      root: true
    })
    await commit("NAVIGATE_ACTIVE_DIALOG_BACK")
  },
  resetPassword ({ commit, state }, payload) {
    return this.$repositories.forgotPassword.create(payload)
  },
  changeUserPassword ({ commit, state }, payload) {
    return this.$repositories.changeUserPassword.create(payload)
  },
  register ({ commit, state }, payload) {
    return this.$repositories.register.create(payload)
  }
}

// Getters ====================

export const getters = {
  ...globalGetters,
  getAnxiety (state) {
    return state.anxiety
  },
  showAnxiety (state) {
    return state.showAnxiety
  },
  getFear (state) {
    return state.fear
  }
}
