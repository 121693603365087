// State ======================
import lmsActions from "../api/LmsActions.js";
import lmsMutations from "../api/LmsMutations.js";
import lmsGetters from "../api/LmsGetters.js";
export const namespaced = true;

export const state = () => ({
  type: "lmsSections",
  pageLayout: "StructureTable",
  relations: [
    {
      name: "lmsCourses",
      value: "lmsCourses",
      translate: "courses",
      export: false,
    },
    {
      name: "lmsLessons",
      value: "lmsLessons",
      translate: "lessons",
      export: false,
    },
  ],
  all: {},
  reference: {},
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: "name",
    page: 1,
    sortDesc: [],
  },
  total: 0,
  print: false,
  print_path: "",
  export: false,
  search: null,
  bound: {},
  stub: {
    lmsLessons: [],
    lmsCourses: [],
  },
  filter: {},
  include: [],
  headers: {},
  notifications: {},
});

// Mutations ==================

export const mutations = {
  ...lmsMutations,
};

// Actions ====================

export const actions = {
  ...lmsActions,
};

// Getters ====================

export const getters = {
  ...lmsGetters,
};
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
