import globalActions from "../api/GlobalActions.js"
import globalMutations from "../api/GlobalMutations.js"
import globalGetters from "../api/GlobalGetters.js"

export const state = () => ({
  type: "certificates",
  relations: [
    {
      name: "users",
      value: "users",
      translate: "user",
      export: false
    },
    {
      name: "courses",
      value: "courses",
      translate: "course",
      export: false
    },
    {
      name: "files",
      value: "files",
      translate: "file",
      export: false
    }

  ],
  all: {},
  reference: {},
  active: false,
  page: 1,
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: "title",
    page: 1,
    sortDesc: []
  },
  total: 0,
  print: false,
  print_path: "",
  export: false,
  search: null,
  bound: {},
  stub: {
    course: {},
    user: {},
    file: {}
  },
  filter: {},
  include: "",
  headers: {},
  notifications: {}
})

// Mutations ==================

export const mutations = {
  ...globalMutations
}

// Actions ====================

export const actions = {
  ...globalActions
}

// Getters ====================

export const getters = {
  ...globalGetters
}
