

export default {
  data () {
    return {
      loading: false,
      courses: [],
      search: null,
      selectbox: null,
      path: "courses"
    }
  },
  watch: {
    search (newValue, oldValue) {
      if (newValue) {
        this.debouncedQuery()
      } else {
        this.courses = []
      }
    }
  },
  created () {
    this.debouncedQuery = _.debounce(this.querySelections, 500)
  },
  methods: {
    async querySelections () {
      this.loading = true

      const params = {
        "filter_scope[search]": this.search,
        include: "cover,files"
      }

      const results = await this.$repositories[this.path].index(params)
      this.courses = results.data

      this.loading = false
    }
  }
}
