import createRepository from "~/api/Repository.js"

export default (ctx, inject) => {
  const repositoryWithAxios = createRepository(ctx.$axios)

  /* sample: resourceName: respositoryWithAxios("resourceName")
     * This is accessible in the store with the syntax:
     * this.$repositories.resourcename
     */
  const repositories = {
    register: repositoryWithAxios("register"),
    forgotPassword: repositoryWithAxios("forgotPassword"),
    changeUserPassword: repositoryWithAxios("changeUserPassword"),
    users: repositoryWithAxios("users"),

    courses: repositoryWithAxios("courses"),
    lessons: repositoryWithAxios("lessons"),
    certificates: repositoryWithAxios("certificates"),

    presentations: repositoryWithAxios("presentations"),
    families: repositoryWithAxios("families"),

    userCourses: repositoryWithAxios("users", "courses"),
    userLessons: repositoryWithAxios("users", "lessons"),
    courseLessons: repositoryWithAxios("courses", "lessons")
  }
  inject("repositories", repositories)
}
