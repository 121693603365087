
export default {
  name: "Student",
  data () {
    return {
      drawer: false,
      right: true,
      group: null
    }
  },
  computed: {
    showBanner () {
      return this.$route.name === "index"
    },
    user () {
      return this.$auth.user
    },
    isFluid () {
      return !!this.$store.state.system.is_layout_fluid
    },
    isAdmin () {
      return this.user.data.roles.includes("super admin") || this.user.data.roles.includes("admin")
    }
  },
  methods: {
    logout () {
      this.$auth.logout().then(() => this.$router.push("/login"))
    }
  }
}
