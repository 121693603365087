import Vue from "vue"
import _ from "lodash"

import XLSX from "xlsx"
import VueClipboard from "vue-clipboard2"
import EdgeForm from "edge-form"

// import VuetifyDaterangePicker from "vuetify-daterange-picker";
// import "vuetify-daterange-picker/dist/vuetify-daterange-picker.css";

if (!Vue.__my_mixin__) {
  Vue.__my__mixin__ = true
  Vue.mixin({
    methods: {
      showErrorSnack (errorMessage) {
        this.$store.commit("lmsSystem/SET_SNACK", "ERROR: " + errorMessage)
        const snackSettings = {
          color: "error",
          top: false,
          timeout: 2000,
          right: true
        }
        this.$store.commit("lmsSystem/set_snackSettings", snackSettings)
      },
      showSuccessSnack (errorMessage) {
        this.$store.commit("lmsSystem/SET_SNACK", "Success: " + errorMessage)
        const snackSettings = {
          color: "secondary",
          top: false,
          timeout: 2000,
          right: true
        }
        this.$store.commit("lmsSystem/set_snackSettings", snackSettings)
      },
      isEmptyObject (obj) {
        return JSON.stringify(obj) === "{}"
      }
    }
  })
}

VueClipboard.config.autoSetContainer = true

Vue.use(_)

Vue.use(XLSX)
Vue.use(VueClipboard)
Vue.use(EdgeForm)
