// State ======================
import Vue from "vue"
import { schema } from "normalizr"
import lmsGetters from "../api/LmsGetters.js"
import { resolveSchema } from "../api/Helpers"
export const namespaced = true

const sectionschemasSchema = new schema.Entity("LmsSectionschemas")
sectionschemasSchema.define({})

export const state = () => ({
  type: "lmsSectionschemas",
  schemaType: "sectionschemasSchema",
  relations: [],
  all: {},
  reference: {
    /* for showing paginated resources ... needs a bit more work. */
  },
  active: false,
  page: 1,
  filter: {},
  pageCount: 0,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 150,
    sort: true,
    sortBy: "due_on",
    page: 1,
    sortDesc: []
  },
  total: 0,
  print: false,
  export: false,
  data: null,
  isLoading: false,
  indexes: [],
  types: [],
  columns: [],
  fieldMap: {},
  savedOrgColumns: [],
  activeFilters: [],
  bound: {},
  stub: {},
  include: [],
  headers: [
    {
      align: "left",
      field: "label",
      text: "",
      icon: "mdi-label",
      protected: true,
      display: true,
      tableDisplay: false,
      filterDisplay: false,
      editable: true,
      label: "Label",
      type: "text",
      value: "label",
      used: true,
      permission: "",
      flex: { xs: 12, sm: 12 },
      order: 1
    },
    {
      align: "left",
      field: "used",
      text: "",
      icon: "mdi-switch",
      protected: true,
      display: true,
      tableDisplay: false,
      filterDisplay: false,
      editable: true,
      label: "Use Field",
      type: "checkbox",
      value: "used",
      used: true,
      permission: "",
      flex: { xs: 12, sm: 12 },
      order: 1.1
    },
    {
      align: "left",
      field: "createDisplay",
      text: "",
      icon: "mdi-textbox",
      protected: true,
      display: true,
      tableDisplay: false,
      filterDisplay: false,
      editable: true,
      label: "Available in Create View",
      type: "checkbox",
      value: "createDisplay",
      used: true,
      permission: "",
      flex: { xs: 12, sm: 12 },
      order: 1.2
    },
    {
      align: "left",
      field: "display",
      text: "",
      icon: "mdi-textbox",
      protected: true,
      display: true,
      tableDisplay: false,
      filterDisplay: false,
      editable: true,
      label: "Available in Edit View",
      type: "checkbox",
      value: "display",
      used: true,
      permission: "",
      flex: { xs: 12, sm: 12 },
      order: 1.3
    },
    {
      align: "left",
      field: "tableDisplay",
      text: "",
      icon: "mdi-textbox",
      protected: true,
      display: true,
      tableDisplay: false,
      filterDisplay: false,
      editable: true,
      label: "Show in table view",
      type: "checkbox",
      value: "tableDisplay",
      used: true,
      permission: "",
      flex: { xs: 12, sm: 12 },
      order: 1.5
    },
    {
      align: "left",
      field: "filterDisplay",
      text: "",
      icon: "mdi-filter",
      protected: true,
      display: true,
      tableDisplay: false,
      filterDisplay: false,
      editable: true,
      label: "Show in filter",
      type: "checkbox",
      value: "filterDisplay",
      used: true,
      permission: "",
      flex: { xs: 12, sm: 12 },
      order: 1.6
    },
    {
      align: "left",
      field: "protected",
      text: "",
      icon: "mdi-shield-edit-outline",
      protected: true,
      display: true,
      tableDisplay: false,
      filterDisplay: false,
      editable: true,
      label: "Allow member to edit this field in the member portal",
      type: "checkbox",
      value: "protected",
      used: true,
      permission: "",
      flex: { xs: 12, sm: 12 },
      order: 2
    }
  ]
})

// Mutations ==================

export const mutations = {
  MERGE (state, resource) {
    const index = state.all[resource.section_name]
    if (index === -1) {
      Vue.set(state.all, resource.section_name, resource)
    } else {
      Vue.set(state.all, resource.section_name, resource)
    }
  },
  setFieldMap: (state, payload) => {
    state.fieldMap = payload[0]
  },
  addHeaders (state, headers) {
    Object.assign(state.fieldMap, headers)
  },
  add (state, heading) {
    Vue.set(state.all, heading.field_name, heading)
  },
  addHeader (state, column) {
    if (state.columns.length === 0) {
      const d = {
        text: "Organization",
        align: "left",
        value: "company_name",
        field: "org",
        type: "read_only",
        protected: true
      }
      state.columns.push(d)
      state.savedOrgColumns.push(d)
    }
    const f = {
      text: column.title,
      field: column.field_name,
      type: column.type,
      value: column.field_name,
      align: "left",
      protected: false,
      sortable: true
    }
    if (column.type === "dropdown") {
      f.items = column.dropdown
    }

    state.columns.push(f)
    if (
      column.field_name === "anniversary" ||
      column.field_name === "status" ||
      column.field_name === "member_type"
    ) {
      if (state.savedOrgColumns.some(c => c.field !== column.field_name)) {
        state.savedOrgColumns.push(f)
      }
    }
  },
  addbinding (state, filter) {
    if (filter.type === "date") {
      Vue.set(state.bound, filter.field_name, { start: "", end: "" })
    } else {
      Vue.set(state.bound, filter.field_name, "")
    }
  },
  setSavedOrgColumns: (state, payload) => {
    state.savedOrgColumns = payload
  },
  update (state, { id, member }) {
    state.all[id] = member
  },
  updateBound (state, { key, value }) {
    state.bound[key] = value
  },
  resetBound (state, key) {
    state.bound[key] = ""
  },
  loading (state, data) {
    state.isLoading = data
  }
}

// Actions ====================
export const actions = {
  all ({ commit, state, dispatch }, payload) {
    payload.forEach((item) => {
      commit("MERGE", resolveSchema(item))
      const model = item.section_name
      const capModel =
        model.charAt(0).toUpperCase() + model.substring(1, model.length)

      dispatch(`lms${capModel}/MERGEHEADERS`, item.schema, {
        root: true
      })
      dispatch(`lms${capModel}/UpdateBound`, item.schema, {
        root: true
      })
    })
  },
  async list ({ commit, state, getters, dispatch }) {
    const params = {}
    const results = await this.$lmsrepositories[state.type].index(params)

    dispatch("all", results.data)
  },
  async update ({ commit, state, getters, dispatch }, { id, payload }) {
    const results = await this.$lmsrepositories[state.type].update({
      id,
      payload
    })
    dispatch("all", results.data)
  }
}

// Getters ====================

export const getters = {
  ...lmsGetters
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
