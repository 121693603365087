// State ======================
import Vue from "vue";
import lmsActions from "../api/LmsActions.js";
import lmsMutations from "../api/LmsMutations.js";
import lmsGetters from "../api/LmsGetters.js";
export const namespaced = true;

export const state = () => ({
  type: "lmsFilterschemas",
  schemaType: "filterschemas",
  all: {},
  relations: [],
  reference: {
    /* for prefetching paginated pages */
  },
  active: false,
  page: 1,
  pagination: {
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    itemsPerPage: 50,
    sort: false,
    sortBy: "name",
    page: 1,
    descending: true,
  },
  total: 0,
  print: false,
  export: false,
  search: null,
  stub: {},
  bound: {
    filter_name: "",
    model: "",
    private: false,
    schema: {},
  },
  filter: {},
  //members are called contractors on the server
  include: [],
  headers: {},
  notifications: {},
});

// Mutations ==================

export const mutations = {
  ...lmsMutations,
};

// Actions ====================

export const actions = {
  ...lmsActions,
};

// Getters ====================

export const getters = {
  ...lmsGetters,
};
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
